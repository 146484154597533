import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Text } from 'rebass'

const Spinner = ({ size = 20, color = '#2FA69A', message }) => (
  <Fragment>
    <svg
      width={size}
      height={size}
      viewBox='0 0 31 31'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
    >
      <circle cx='15.5' cy='15.5' r='13.5' stroke={color} strokeWidth='4' strokeOpacity='.5' />
      <path
        d='M29 15.5C29 22.9558 22.9558 29 15.5 29'
        stroke={color}
        strokeWidth='4'
        strokeLinecap='round'
      >
        <animateTransform
          attributeName='transform'
          type='rotate'
          from='0 15.5 15.5'
          to='360 15.5 15.5'
          dur='0.5s'
          repeatCount='indefinite'
        />
      </path>
    </svg>
    {message && <Text fontWeigth='semibold' ml={2}>{message}</Text>}
  </Fragment>
)

Spinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  message: PropTypes.string
}

export default Spinner
