import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { useUpdateBooking } from '../../../../services/api/useProject'
import { useBookingStore } from '../../../../services/store/useBookingStore'
import { POSTAL_CODES } from '../../data/postalCodes'
import { SpinnerLoader } from '../SpinnerLoader'
import { set } from 'date-fns'

export const Step3: FC = () => {
  const updateProject = useUpdateBooking()
  const { incrementStep, updateForm, form, createdProjectId } = useBookingStore()
  const postalCodeInsidePhysicalMeetingArea = POSTAL_CODES.some((code) => code === form.postalCode)
  const [loading, setLoading] = useState(false)

  const nextStep = async (meetingType: 'physical' | 'digital') => {
    setLoading(true);
    window.dataLayer.push({
      event: 'b_complete_selected_befaring',
      befaring_type: meetingType,
    })
    updateForm('meetingType', meetingType)
    await updateProject.mutateAsync({
      createdProjectId,
      meetingType,
    })
    setLoading(false);
    incrementStep()
  }

  if(loading) {
    <SpinnerWrapper><SpinnerLoader /></SpinnerWrapper>
  }
  return (
    <StyledStep3>
      <span>
        <button
          disabled={!postalCodeInsidePhysicalMeetingArea || loading}
          onClick={() => {
            nextStep('physical')
          }}
        >
          <div>
            <p>Fysisk</p>
            <p>Ved fysisk befaring tar vi et reisegebyr på 2500,- inkl mva.</p>
          </div>
          <p className="price">Fra 2500,-</p>
        </button>
        {!postalCodeInsidePhysicalMeetingArea && (
          <div className="postal-code-warning">
            <ErrorOutlineIcon /> <span>Vi tilbyr kun fysisk befaring i Oslo</span>
          </div>
        )}
      </span>
      <button
        onClick={() => {
          nextStep('digital')
        }}
      >
        <div>
          <p>Digitalt</p>
          <p>Befaring via videmøte. Gratis</p>
        </div>
      </button>
    </StyledStep3>
  )
}


const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
`


const StyledStep3 = styled.section`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 100px;
  .postal-code-warning {
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 20px 20px;
    background-color: #fef1bc;
    color: #65270e;
    border-radius: 4px;
    svg {
      margin-right: 10px;
    }
  }

  button {
    width: 100%;
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr 100px;
    text-align: left;
    padding: 20px;
    background-color: inherit;
    border: 2px solid #dfe2e7;
    border-radius: 4px;
    div {
      display: flex;
      flex-direction: column;
      p:first-of-type {
        font-size: 1.1em;
        margin-bottom: 8px;
        font-weight: 600;
      }
    }
    .price {
      font-weight: 600;
      color: #0000f2;
    }
    :disabled {
      cursor: not-allowed;
    }
  }
`
