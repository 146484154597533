import React, { Fragment } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import { Box, Flex, Text, Link as RebassLink } from 'rebass'
import { object, string } from 'yup'
import { Link } from '@reach/router'
import styled from 'styled-components'
import { Container, Row, Col } from '../Grid'
import { TextSmall } from '../Typography'
import Button from '../Button'
import { NewsLetterInput, FormError, Error } from '../FormElements'
// Constants
import * as routes from '../../constants/routes'

const validationSchema = object().shape({
  email: string().email('Du må fylle inn en gyldig e-postadresse'),
})

const StyledLink = styled(Link)`
  text-decoration: none;
  display: block;
`

const StyledExternalLink = styled(RebassLink)`
  text-decoration: none;
  display: block;
`

const FooterLink = (props) => (
  <StyledLink {...props}>
    <TextSmall color="midGray" as="span">
      {props.children}
    </TextSmall>
  </StyledLink>
)

const ExternalFooterLink = (props) => (
  <StyledExternalLink {...props}>
    <TextSmall color="midGray" as="span">
      {props.children}
    </TextSmall>
  </StyledExternalLink>
)

const Footer = () => (
  <Fragment>
    <Box pt="1px" bg="lightGray" width={1} />
    <Container py={8}>
      <Row>
        <Col width={[1, 1, 1 / 4]} mb={[5, 5, 0]}>
          <TextSmall fontWeight="bold">Arkivet</TextSmall>
          <FooterLink to="/designers">Utforsk designere</FooterLink>
          <FooterLink to="/signup">Bli kunde</FooterLink>
          <FooterLink to="/signup?designer">Registrer deg som designer</FooterLink>
          <FooterLink to="/page/om-arkivet">Om oss</FooterLink>
          <FooterLink to="/page/gavekort">
            Kjøp gavekort
            <Box
              as="span"
              bg="actionBlue"
              fontSize={0}
              color="white"
              fontWeight="semibold"
              p={1}
              ml={2}
              style={{ borderRadius: 4 }}
            >
              NY!
            </Box>
          </FooterLink>
          <FooterLink to={`${routes.LOG_OUT}`}>Logg ut</FooterLink>
        </Col>
        <Col width={[1, 1, 1 / 4]} mb={[5, 5, 0]}>
          <TextSmall fontWeight="bold">Aktiviteter</TextSmall>
          <FooterLink to="/page/events">Events</FooterLink>
        </Col>
        <Col width={[1, 1, 1 / 4]} mb={[5, 5, 0]}>
          <TextSmall fontWeight="bold">Hjelp</TextSmall>
          <ExternalFooterLink href="https://intercom.help/arkivet/nb/">
            Spørsmål og svar
          </ExternalFooterLink>
          <FooterLink to="/page/personvern">Personvernerklæring</FooterLink>
        </Col>
        <Col width={[1, 1, 1 / 4]} mb={[5, 5, 0]}>
          <TextSmall fontWeight="bold">Følg oss</TextSmall>
          <ExternalFooterLink href="https://blogg.arkivet.co/">Blogg</ExternalFooterLink>
          <ExternalFooterLink href="https://www.facebook.com/arkivet.co">
            Facebook
          </ExternalFooterLink>
          <ExternalFooterLink href="https://www.instagram.com/arkivet_co/">
            Instagram
          </ExternalFooterLink>
          <ExternalFooterLink href="https://no.pinterest.com/arkivetco/">
            Pinterest
          </ExternalFooterLink>
        </Col>
      </Row>
    </Container>
    <Box py={8} bg="black">
      <Container>
        <Row>
          <Col width={[1, 4 / 12]} mb={[3, 0]}>
            <TextSmall color="white">
              Registrer deg for å motta vårt nyhetsbrev. Få med deg det siste fra Arkivet,
              interiørdesign, samt kommende eventer og happenings!
            </TextSmall>
          </Col>
          <Col width={[1, 5 / 12]} mb={[3, 0]}>
            <Formik
              initialValues={{ email: '' }}
              validationSchema={validationSchema}
              onSubmit={async (values, actions) => {
                try {
                  const body = { data__Email: values.email }
                  await fetch('https://hooks.zapier.com/hooks/catch/124325/fxyag6/', {
                    method: 'POST',
                    body: JSON.stringify(body),
                  })
                  actions.setSubmitting(false)
                  actions.resetForm()
                } catch (error) {
                  actions.setSubmitting(false)
                  actions.setErrors(error)
                }
              }}
              render={({ isSubmitting, errors, isValid }) => (
                <Form>
                  <FormError errors={errors} />
                  <Flex alignItems="center">
                    <Text hidden as="label" htmlFor="email">
                      E-postadresse
                    </Text>
                    <NewsLetterInput placeholder="E-postadresse" name="email" />
                    <Button
                      data-testid="newsletter-submit"
                      loading={isSubmitting ? isSubmitting : undefined}
                      type="submit"
                      variant="tertiary"
                    >
                      Send
                    </Button>
                  </Flex>
                  <ErrorMessage component={Error} name="email" />
                </Form>
              )}
            />
          </Col>
          <Col width={[1, 3 / 12]}>
            <Flex justifyContent={['center', 'flex-end']}>
              <svg
                width="55"
                height="54"
                viewBox="0 0 55 54"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.3619 0H21.4414L32.6384 4.12941C32.7178 4.12941 32.7972 4.20882 32.7972 4.28824V5.71765C32.7972 5.79706 32.7178 5.87647 32.6384 5.87647L21.4414 10.0059H21.3619C21.2825 10.0059 21.2031 9.92647 21.2031 9.84706V8.73529C21.2031 8.65588 21.2825 8.57647 21.3619 8.49706L24.6178 7.38529V2.62059L21.3619 1.50882C21.2825 1.50882 21.2031 1.42941 21.2031 1.27059V0.158824C21.2031 0.0794118 21.2031 0 21.3619 0ZM25.8884 3.09706V6.90882L31.1296 5.00294L25.8884 3.09706Z"
                  fill="white"
                />
                <path
                  d="M32.6384 54H32.559L21.3619 49.8706C21.2825 49.8706 21.2031 49.7912 21.2031 49.7118V48.2824C21.2031 48.203 21.2825 48.1236 21.3619 48.1236L32.559 43.9941H32.6384C32.7178 43.9941 32.7972 44.0735 32.7972 44.153V45.2647C32.7972 45.3441 32.7178 45.4236 32.6384 45.503L29.3825 46.6147V51.3794L32.6384 52.4912C32.7178 52.4912 32.7972 52.5706 32.7972 52.7294V53.8412C32.7972 53.9206 32.7972 54 32.6384 54ZM28.1119 50.903V47.0912L22.8708 48.9971L28.1119 50.903Z"
                  fill="white"
                />
                <path
                  d="M54.002 21.3619V21.4414L49.8726 32.6384C49.8726 32.7178 49.7932 32.7972 49.7137 32.7972H48.2843C48.2049 32.7972 48.1255 32.7178 48.1255 32.6384L43.9961 21.4414V21.3619C43.9961 21.2825 44.0755 21.2031 44.1549 21.2031H45.2667C45.3461 21.2031 45.4255 21.2825 45.5049 21.3619L46.6167 24.6178H51.3814L52.4932 21.3619C52.4932 21.2825 52.5726 21.2031 52.7314 21.2031H53.8432C53.9226 21.2031 54.002 21.2031 54.002 21.3619ZM50.9049 25.8884H47.0932L48.999 31.1296L50.9049 25.8884Z"
                  fill="white"
                />
                <path
                  d="M0 32.6383V32.5589L4.12941 21.3618C4.12941 21.2824 4.20882 21.203 4.28824 21.203H5.71765C5.79706 21.203 5.87647 21.2824 5.87647 21.3618L10.0059 32.5589V32.6383C10.0059 32.7177 9.92647 32.7971 9.84706 32.7971H8.73529C8.65588 32.7971 8.57647 32.7177 8.49706 32.6383L7.38529 29.3824H2.62059L1.50882 32.6383C1.50882 32.7177 1.42941 32.7971 1.27059 32.7971H0.158824C0.0794118 32.7971 0 32.7971 0 32.6383ZM3.09706 28.1118H6.90882L5.00294 22.8706L3.09706 28.1118Z"
                  fill="white"
                />
                <path
                  d="M54.002 21.3619V21.4414L49.8726 32.6384C49.8726 32.7178 49.7932 32.7972 49.7137 32.7972H48.2843C48.2049 32.7972 48.1255 32.7178 48.1255 32.6384L43.9961 21.4414V21.3619C43.9961 21.2825 44.0755 21.2031 44.1549 21.2031H45.2667C45.3461 21.2031 45.4255 21.2825 45.5049 21.3619L46.6167 24.6178H51.3814L52.4932 21.3619C52.4932 21.2825 52.5726 21.2031 52.7314 21.2031H53.8432C53.9226 21.2031 54.002 21.2031 54.002 21.3619ZM50.9049 25.8884H47.0932L48.999 31.1296L50.9049 25.8884Z"
                  fill="white"
                />
                <path
                  d="M0 32.6383V32.5589L4.12941 21.3618C4.12941 21.2824 4.20882 21.203 4.28824 21.203H5.71765C5.79706 21.203 5.87647 21.2824 5.87647 21.3618L10.0059 32.5589V32.6383C10.0059 32.7177 9.92647 32.7971 9.84706 32.7971H8.73529C8.65588 32.7971 8.57647 32.7177 8.49706 32.6383L7.38529 29.3824H2.62059L1.50882 32.6383C1.50882 32.7177 1.42941 32.7971 1.27059 32.7971H0.158824C0.0794118 32.7971 0 32.7971 0 32.6383ZM3.09706 28.1118H6.90882L5.00294 22.8706L3.09706 28.1118Z"
                  fill="white"
                />
                <path
                  d="M42.0894 3.89103C42.0894 3.89103 42.0894 3.97044 42.1688 3.97044L47.1718 14.8499C47.2512 14.9293 47.2512 15.0087 47.1718 15.0881L46.1394 16.1204C46.06 16.1999 45.9806 16.1999 45.9012 16.1204L35.0218 11.1175C35.0218 11.1175 34.9424 11.1175 34.9424 11.0381C34.863 10.9587 34.863 10.8793 34.9424 10.7999L35.7365 10.0057C35.8159 9.92632 35.8953 9.92632 35.9747 10.0057L39.0718 11.4351L42.4071 8.09985L40.9777 5.00279C40.8983 4.92338 40.8983 4.84397 40.9777 4.76456L41.7718 3.97044C41.9306 3.81162 42.01 3.81162 42.0894 3.89103ZM43.1218 9.37044L40.4218 12.0704L45.5041 14.4528L43.1218 9.37044Z"
                  fill="white"
                />
                <path
                  d="M11.9114 50.1089L11.832 50.0295L6.82909 39.1501C6.74968 39.0707 6.74968 38.9913 6.82909 38.9119L7.86144 37.8795C7.94085 37.8001 8.02027 37.8001 8.09968 37.8795L18.9791 42.8824C18.9791 42.8824 19.0585 42.8824 19.0585 42.9619C19.1379 43.0413 19.1379 43.1207 19.0585 43.2001L18.2644 43.9942C18.185 44.0736 18.1056 44.0736 18.0261 43.9942L14.9291 42.5648L11.5938 45.9001L13.0232 48.9972C13.1026 49.0766 13.1026 49.156 13.0232 49.2354L12.2291 50.0295C12.0703 50.1883 11.9909 50.1883 11.9114 50.1089ZM10.8791 44.6295L13.5791 41.9295L8.49674 39.5472L10.8791 44.6295Z"
                  fill="white"
                />
                <path
                  d="M50.1093 42.0883L50.0299 42.1677L39.1505 47.1706C39.071 47.25 38.9916 47.25 38.9122 47.1706L37.8799 46.1383C37.8005 46.0589 37.8005 45.9794 37.8799 45.9L42.8828 35.0206C42.8828 35.0206 42.8828 34.9412 42.9622 34.9412C43.0416 34.8618 43.121 34.8618 43.2005 34.9412L43.9946 35.7353C44.074 35.8147 44.074 35.8942 43.9946 35.9736L42.5652 39.0706L45.9005 42.4059L48.9975 40.9765C49.0769 40.8971 49.1563 40.8971 49.2358 40.9765L50.0299 41.7706C50.1887 41.9294 50.1887 42.0089 50.1093 42.0883ZM44.6299 43.1206L41.9299 40.4206L39.5475 45.503L44.6299 43.1206Z"
                  fill="white"
                />
                <path
                  d="M3.89201 11.9117L3.97142 11.8323L14.8508 6.82933C14.9302 6.74992 15.0097 6.74992 15.0891 6.82933L16.1214 7.86169C16.2008 7.9411 16.2008 8.02051 16.1214 8.09992L11.1185 18.9793C11.1185 18.9793 11.1185 19.0587 11.0391 19.0587C10.9597 19.1382 10.8802 19.1382 10.8008 19.0587L10.0067 18.2646C9.9273 18.1852 9.9273 18.1058 10.0067 18.0264L11.4361 14.9293L8.10083 11.594L5.00377 13.0235C4.92436 13.1029 4.84495 13.1029 4.76554 13.0235L3.97142 12.2293C3.81259 12.0705 3.81259 11.9911 3.89201 11.9117ZM9.37142 10.8793L12.0714 13.5793L14.4538 8.49698L9.37142 10.8793Z"
                  fill="white"
                />
                <path
                  d="M50.1093 42.0883L50.0299 42.1677L39.1505 47.1706C39.071 47.25 38.9916 47.25 38.9122 47.1706L37.8799 46.1383C37.8005 46.0589 37.8005 45.9794 37.8799 45.9L42.8828 35.0206C42.8828 35.0206 42.8828 34.9412 42.9622 34.9412C43.0416 34.8618 43.121 34.8618 43.2005 34.9412L43.9946 35.7353C44.074 35.8147 44.074 35.8942 43.9946 35.9736L42.5652 39.0706L45.9005 42.4059L48.9975 40.9765C49.0769 40.8971 49.1563 40.8971 49.2358 40.9765L50.0299 41.7706C50.1887 41.9294 50.1887 42.0089 50.1093 42.0883ZM44.6299 43.1206L41.9299 40.4206L39.5475 45.503L44.6299 43.1206Z"
                  fill="white"
                />
                <path
                  d="M3.89201 11.9117L3.97142 11.8323L14.8508 6.82933C14.9302 6.74992 15.0097 6.74992 15.0891 6.82933L16.1214 7.86169C16.2008 7.9411 16.2008 8.02051 16.1214 8.09992L11.1185 18.9793C11.1185 18.9793 11.1185 19.0587 11.0391 19.0587C10.9597 19.1382 10.8802 19.1382 10.8008 19.0587L10.0067 18.2646C9.9273 18.1852 9.9273 18.1058 10.0067 18.0264L11.4361 14.9293L8.10083 11.594L5.00377 13.0235C4.92436 13.1029 4.84495 13.1029 4.76554 13.0235L3.97142 12.2293C3.81259 12.0705 3.81259 11.9911 3.89201 11.9117ZM9.37142 10.8793L12.0714 13.5793L14.4538 8.49698L9.37142 10.8793Z"
                  fill="white"
                />
              </svg>
            </Flex>
          </Col>
        </Row>
        <Row my={3}>
          <Box
            as="hr"
            bg="graphite"
            width={1}
            style={{
              border: 0,
              height: '1px',
            }}
          />
          <Col width={[1]} mt={[4, 4]}>
            <TextSmall color="white">Plan My Space AS</TextSmall>
            <TextSmall color="white">Bygdøy Allè 5</TextSmall>
            <TextSmall color="white">0257 Oslo</TextSmall>
            <StyledExternalLink href="tel:+4740851022">
              <TextSmall color="white">+47 40851022</TextSmall>
            </StyledExternalLink>
            <StyledExternalLink href="mailto:post@arkivet.co">
              <TextSmall color="white">post@arkivet.co</TextSmall>
            </StyledExternalLink>
          </Col>
        </Row>
      </Container>
    </Box>
  </Fragment>
)

export default Footer
