export function setLocalStorageItem(key: string, value: any) {
  if (window.localStorage) {
    window.localStorage.setItem(key, JSON.stringify(value))
  }
}

export function getLocalStorageItem(key: string) {
  try {
    const stored = window.localStorage.getItem(key)
    return stored == null ? undefined : JSON.parse(stored)
  } catch (e) {
    return e
  }
}
