import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { Button as RebassButton } from 'rebass'
import { saturate } from 'polished'
import Spinner from '../Spinner'

const loading = css`
  opacity: 0.3;
  cursor: not-allowed;
`

const Base = styled(RebassButton)`
  transition: all 0.2s;
  cursor: pointer;
  line-height: 1;
  padding-left: ${(props) =>
    props.size === 'small' ? props.theme.space[3] : props.theme.space[4]}px;
  padding-right: ${(props) =>
    props.size === 'small' ? props.theme.space[3] : props.theme.space[4]}px;
  padding-top: ${(props) =>
    props.size === 'small' ? props.theme.space[2] : props.theme.space[3]}px;
  padding-bottom: ${(props) =>
    props.size === 'small' ? props.theme.space[2] : props.theme.space[3]}px;
  font-weight: 500;
  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
  ${(props) => props.loading && loading};
`

const Primary = styled(Base)`
  background-color: '#2520ef'; /*${(props) => props.theme.colors.actionBlue};*/
  &:hover {
    background-color: ${(props) => saturate(0.3, '#2520ef')};
  }
`

const Secondary = styled(Base)`
  background-color: white;
  color: ${(props) => props.theme.colors.actionBlue};
  border: 1px solid ${(props) => props.theme.colors.actionBlue};
`

const Tertiary = styled(Base)`
  background-color: transparent;
  color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.midGray};
  border-radius: 0px 4px 4px 0px;
`

const Minimal = styled(Base)`
  background-color: ${(props) => props.theme.colors.uberlightGray};
  color: ${(props) => props.theme.colors.black};
`

const Special = styled(Base)`
  background-color: ${(props) => props.theme.colors.actionBlue};
  padding-top: 12px;
  padding-bottom: 12px;
  &:hover {
    background-color: ${(props) => saturate(0.3, props.theme.colors.actionBlue)};
  }
`

const Text = styled(RebassButton)`
  display: 'inline-block';
  background: none;
  font-size: ${(props) => props.theme.fontSizes[3]}px;
  padding: ${(props) => props.theme.space[1]}px;
  color: ${(props) => props.theme.colors.black};
  border-bottom: 1px solid ${(props) => props.theme.colors.black};
  border-radius: 0;
  cursor: pointer;
  font-weight: 400;
`

const Upload = styled.label`
  font-family: ${(props) => props.theme.fonts.sans};
  cursor: pointer;
  line-height: 1;
  padding-left: ${(props) =>
    props.size === 'small' ? props.theme.space[3] : props.theme.space[4]}px;
  padding-right: ${(props) =>
    props.size === 'small' ? props.theme.space[3] : props.theme.space[4]}px;
  padding-top: ${(props) =>
    props.size === 'small' ? props.theme.space[2] : props.theme.space[3]}px;
  padding-bottom: ${(props) =>
    props.size === 'small' ? props.theme.space[2] : props.theme.space[3]}px;
  font-weight: 500;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.uberlightGray};
  color: ${(props) => props.theme.colors.black};
  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
  ${(props) => props.loading && loading};
  input[type='file'] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`

/*
  Usage:
  import Button from '/components/Button'
  <Button variant='minimal' size='small'>Click Me</Button>  --> Renders small minimal button
  <Button>Click Me</Button>                                 --> Renders default button
*/

class Button extends Component {
  variants = {
    primary: Primary,
    secondary: Secondary,
    minimal: Minimal,
    text: Text,
    upload: Upload,
    special: Special,
    tertiary: Tertiary,
  }

  render() {
    const ButtonVariant = this.variants[this.props.variant] || this.variants.primary
    return (
      <ButtonVariant {...this.props}>
        {this.props.loading && <Spinner />}
        {this.props.children}
      </ButtonVariant>
    )
  }
}

export default Button
