import { ArrowBackSharp } from '@material-ui/icons'
import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import Container from '../../components/Container'
import { LogoPrimary } from '../../components/Logo'
import { useBookingStore } from '../../services/store/useBookingStore'
import { Step1 } from './components/steps/Step1'
import { Step2 } from './components/steps/Step2'
import { Step3 } from './components/steps/Step3'
import Step4 from './components/steps/Step4'
import Step5 from './components/steps/Step5'
import Step6 from './components/steps/Step6'
import { Step7 } from './components/steps/Step7'
import { Step8 } from './components/steps/Step8'
import { Step9 } from './components/steps/Step9'
import { Step10 } from './components/steps/Step10'

const STEPS = [
  {
    step: 1,
    title: 'Hva trenger du hjelp med?',
    description:
      'Velg mellom våre kategorier, så matcher vi deg med de designerene som vil passe best for ditt prosjekt.',
    Component: <Step1 />,
  },
  {
    step: 2,
    title: 'Kontaktopplysninger',
    description:
      'Hvordan skal vi kontakte deg i forhold til ditt prosjekt, og hvor skal arbeidet skje?\n Vi bruker dette for for å koble deg til den rette designeren.',
    Component: <Step2 />,
  },
  {
    step: 3,
    title: 'Hvordan ønsker du befaring?',
    Component: <Step3 />,
  },
  {
    step: 4,
    title: 'Når ønsker du oppstartsmøte?',
    description:
      'Velg hvilken uke du ser for deg å ha et oppstartsmøte. Din interiørdesigner vil ta kontakt for og avtale sted og tidspunkt.',
    Component: <Step4 />,
  },
  {
    step: 5,
    title: 'Utvalgt designer',
    description: 'Vi har valgt ut en knippe designere som passer til ditt prosjekt.',
    Component: <Step5 />,
  },
  {
    step: 6,
    title: 'Oppsummering',
    Component: <Step6 />,
  },
  {
    step: 7,
    title: 'Takk for din forespørsel',
    Component: <Step7 />,
  },
  {
    step: 8,
    title: 'Hvilke rom ønsker du hjelp med?',
    Component: <Step8 />,
  },
  {
    step: 9,
    title: 'Hvilken stil beskriver deg best?',
    Component: <Step9 />,
  },
  {
    step: 10,
    title: 'Hva trenger du hjelp til?',
    description:
      'Fortell oss litt mer om prosjektet vi skal finne løsninger for. Jo mer vi vet, jo bedre',
    Component: <Step10 />,
  },
]

export const BookingWizard: FC = () => {
  const { currentStep, form, decrementStep, formSubmitted } = useBookingStore()
  const [activeStep, setActiveStep] = useState(() =>
    STEPS.find((step) => step.step === currentStep)
  )
  useEffect(() => {
    setActiveStep(STEPS.find((step) => step.step === currentStep))
    window.scrollTo(0, 0)
  }, [currentStep])

  return (
    <Container>
      <StyledBookingWizard>
        {!formSubmitted && activeStep ? (
          <>
            {currentStep > 1 && currentStep < 7 && (
              <button onClick={decrementStep} className="back-button">
                <ArrowBackSharp />
              </button>
            )}
            {form && form.designer && (
              <div className="chosen-designer">
                <img
                  alt={form.designer.profileImage.altText}
                  src={form.designer.profileImage.image}
                />
                <p>{`Du ${
                  activeStep.step < 7 ? 'sender forespørsel' : 'har sendt forespørsel'
                }  til ${form.designer.name}`}</p>
              </div>
            )}
            <h1 className="step-header">{activeStep.title}</h1>
            <p className="description">{activeStep.description}</p>
            {activeStep.Component}
          </>
        ) : (
          <CompletedFormMessage />
        )}
      </StyledBookingWizard>
    </Container>
  )
}

const CompletedFormMessage: FC = () => {
  return (
    <StyledCompletedFormMessage>
      <h2>Takk for all informasjonen</h2>
      <LogoPrimary />
      <p>Vi gleder oss til å møte deg</p>
      <p>Din designer vil ta kontakt så snart som mulig</p>
    </StyledCompletedFormMessage>
  )
}

const StyledBookingWizard = styled.section`
  margin: 0 auto;
  min-height: 20vh;
  .back-button {
    background: none;
    border: none;
    color: #0000f2;
    cursor: pointer;
  }
  .step-header {
    text-align: center;
    font-size: 2.25rem;
    font-weight: 600;
    color: #0000f2;
    margin-bottom: 16px;
  }

  .description {
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-bottom: 30px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .chosen-designer {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 125px;
      height: 125px;
      border-radius: 6px;
      object-fit: cover;
    }
    p {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
`

const StyledCompletedFormMessage = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 80px 0;
  h2 {
    color: #0000f2;
    font-size: 2rem;
    font-weight: 600;
  }
`

export const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 10px 10px;
  font-weight: 300;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  background-color: #f8f9fb;
  border: 1px solid #dfe2e7;
`

export const StyledButton = styled.button`
  cursor: pointer;
  background-color: inherit;
  border: 1px solid #0000f2;
  background-color: #0000f2;
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 4px;
  :disabled {
    background-color: #dfe2e7;
    border: 1px solid #dfe2e7;
    color: #0000f2;
    pointer-events: none;
  }
`
