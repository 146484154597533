import styled from 'styled-components'
import { Flex, Box } from 'rebass'
import { media } from '../../constants/theme/breakpoints'

export const Container = styled(Box)`
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  ${media.tablet`
    padding-left: 2rem;
    padding-right: 2rem;
  `};
`

export const WideContainer = styled(Box)`
  max-width: 3000px;
  margin: 0 auto;
`

export const Row = styled(Flex)`
  margin-left: -1rem;
  margin-right: -1rem;
  flex-wrap: wrap;
  justify-content: ${props => (props.align === 'start' ? 'start' : 'center')};
`

export const Col = styled(Box)`
  padding-left: 1rem;
  padding-right: 1rem;
`
