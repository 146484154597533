import React, { FC, FormEvent, useState } from 'react'
import { Link } from '@reach/router'
import styled from 'styled-components'
import { useCreateProjectFromBooking, useSendBookingEmail, useUpdateBooking } from '../../../../services/api/useProject'
import { useBookingStore } from '../../../../services/store/useBookingStore'
import { StyledInput } from '../../BookingWizard'
import { SpinnerLoader } from '../SpinnerLoader'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { eachWeekOfInterval, startOfWeek, endOfWeek, format, getISOWeek } from 'date-fns'


const validEmail = (email?: string): boolean => {
  if (!email) return false
  return !!email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
}


const currentYear = new Date().getFullYear().toString()
const startOfYear = new Date(Number(currentYear), 0, 1)
const endOfYear = new Date(Number(currentYear), 11, 31)

const weeks = eachWeekOfInterval({ start: startOfYear, end: endOfYear })

const weeksWithDates = weeks.map((week) => {
  const startDate = startOfWeek(week, {
    locale: {
      options: { weekStartsOn: 1 },
    }
  })
  const endDate = endOfWeek(week, {
    locale: {
      options: { weekStartsOn: 1 },
    }
  })
  const formattedStartDate = format(startDate, 'dd. MMM')
  const formattedEndDate = format(endDate, 'dd. MMM')
  return { startDate: formattedStartDate, endDate: formattedEndDate, weekNumber: getISOWeek(week) }
})

const Step6: FC<{ firestore?: any }>  = ({ firestore }) => {
  const { incrementStep, updateForm, form, createdProjectId, setStep, insertIntoFirebase, preSelectedDesigner } =
    useBookingStore()
  const updateProject = useUpdateBooking()
  const sendBookingEmail = useSendBookingEmail()
  const [privacyChecked, setPrivacyChecked] = useState(false)
  const [conditionsChecked, setConditionsChecked] = useState(false)
  const [formInput, setFormInput] = useState({
    email: form.email,
    phone: form.phone,
    postalCode: form.postalCode,
  })
  const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)


  const onSubmitForm = async (e: FormEvent<HTMLFormElement>) => {
    window.dataLayer.push({ event: 'b_overview_complete' })
    setLoading(true)

    try {
      const response = await insertIntoFirebase({ ...form, designer: form.designer }, firestore, form.designer?.firebaseDesignerId!!)
      await updateProject.mutateAsync({
        createdProjectId,
        designer: { designerId: form.designer?.firebaseDesignerId!! },
        status: 'request'
      })

      await sendBookingEmail.mutateAsync({
        projectId: createdProjectId,
        firebaseProjectId: response!.firebaseProjectId,
        customerPassword: response!.authUserPassword,
        designerId: form.designer?.firebaseDesignerId!!
      })
    } catch(error) {
      setError(error as any)
      setLoading(false)
      throw error;
    }

    e.preventDefault()

    incrementStep()
  }

  const updateFormInput = (key: 'email' | 'phone' | 'postalCode', value: any) => {
    setFormInput((cs) => ({ ...cs, [key]: value }))
    updateForm(key, value)
  }

  if(error)
    return  <div>
                Det har skjedd en teknisk feil, den har blitt rapportert. Vennligst last siden på nytt og
                forsøk igjen.
              </div>


  if(loading)
    return <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <SpinnerLoader />
        </div>

const selectedDate = weeksWithDates
  .filter(wwd => wwd.weekNumber.toString() === form.selectedAvailability?.split('-')[0])[0]

  return (
   <StyledStep6>
          <form onSubmit={onSubmitForm}>
            <label htmlFor="helpType">
              {form.helpType === 'bedrift' && 'Hva trenger du hjelp med'}
              {form.helpType !== 'bedrift' && <>Hva trenger du hjelp med{!preSelectedDesigner  && <> - <StyledSpan onClick={() => setStep(1)}>Endre</StyledSpan> </>}</>}
            </label>
            <StyledInput
              value={form.helpType}
              name="helpType"
              placeholder={preSelectedDesigner ? 'Spør designer direkte' : 'Hva trenger du hjelp med'}
              type="text"
              disabled
            />
            <label htmlFor="designer">
              {form.helpType === 'bedrift' && 'Valgt designer'}
              {form.helpType !== 'bedrift' && <>Valgt designer - <StyledSpan onClick={() => setStep(5)}>Endre</StyledSpan></>}
            </label>
            <StyledInput
              value={form.designer?.name}
              name="designer"
              placeholder="Valgt designer"
              type="text"
              disabled
            />
             <label htmlFor="date">Uke - <StyledSpan onClick={() => setStep(4)}>Endre</StyledSpan></label>
            <StyledInput
              value={`${selectedDate.weekNumber} (${selectedDate.startDate.toLowerCase()} - ${selectedDate.endDate.toLowerCase()})`}
              name="date"
              placeholder="Datoer"
              type="text"
              disabled
            />
            <label htmlFor="email">Din e-post</label>
            <StyledInput
              value={formInput.email}
              onChange={(e) => updateFormInput('email', e.target.value)}
              name="email"
              placeholder="Kontakt e-post"
              type="email"
            />
            <label htmlFor="phone">Telefon</label>
            <StyledInput
              value={formInput.phone}
              onChange={(e) => updateFormInput('phone', e.target.value)}
              name="phone"
              placeholder="Kontakt telefon"
              type="tel"
            />
            <label htmlFor="postalCode">Postnummer</label>
            <StyledInput
              value={formInput.postalCode}
              onChange={(e) => updateFormInput('postalCode', e.target.value)}
              name="postalCode"
              placeholder="Postnummer"
              type="text"
            />
            <StyledCheckbox>
              <p>
                Jeg godtar Arkivets{' '}
                <Link
                  target="_blank"
                  to="/page/personvern"
                  onClick={() => {
                    window.dataLayer.push({ event: 'b_click_personvern' })
                  }}
                >
                  personvernserklæring
                </Link>
              </p>
              <input
                type="checkbox"
                checked={privacyChecked}
                onChange={({ target }) => setPrivacyChecked(target.checked)}
              />
              <span className="checkmark" />
            </StyledCheckbox>
            <StyledCheckbox>
              <p>
                Jeg godtar Arkivets{' '}
                <Link
                  target="_blank"
                  to="/page/betingelser"
                  onClick={() => {
                    window.dataLayer.push({ event: 'b_click_vilkar' })
                  }}
                >
                  brukervilkår
                </Link>
              </p>
              <input
                type="checkbox"
                checked={conditionsChecked}
                onChange={({ target }) => setConditionsChecked(target.checked)}
              />
              <span className="checkmark" />
            </StyledCheckbox>
            <button
              disabled={
                !validEmail(formInput.email) ||
                !formInput.postalCode ||
                !formInput.phone ||
                formInput.postalCode.length !== 4 ||
                !privacyChecked ||
                !conditionsChecked
              }
              type="submit"
            >
              Gå videre
            </button>
          </form>
        </StyledStep6>

  )
}

const StyledStep6 = styled.section`
  form {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 0 auto;
    margin-top: 60px;
    label {
      font-weight: 600;
      margin-bottom: 8px;
    }
    input {
      margin-bottom: 20px;
    }
    button {
      cursor: pointer;
      color: white;
      text-decoration: none;
      background-color: #0000f2;
      padding: 16px 40px;
      font-weight: 600;
      border-radius: 4px;
      display: inline-block;
      width: fit-content;
      white-space: nowrap;
      border: none;
      margin: 0 auto;
      margin-top: 20px;
      :disabled {
        background-color: lightgray;
        cursor: not-allowed;
      }
    }
    @media screen and (max-width: 800px) {
      width: 90%;
    }
  }
`

const StyledSpan = styled.span`
  cursor: pointer;
  color: blue;
`

const StyledCheckbox = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  :hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: #2196f3;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`
export default compose(firestoreConnect())(Step6)
