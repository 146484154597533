import { AxiosError } from 'axios'
import { useMutation, useQuery } from 'react-query'
import { arkivetApi } from './axios'
import { User } from './user'

export const useCreateProjectFromBooking = () => {
  return useMutation(async (values: Record<any, unknown>) => {
    const res = await arkivetApi.post('/v1/public/booking', {
      ...values,
    })
    return res.data
  })
}

export const useUpdateBooking = () => {
  return useMutation(async (values: Record<any, unknown>) => {
    const res = await arkivetApi.put(`/v1/public/booking/${values.createdProjectId}`, {
      ...values,
    })
    return res.data
  })
}

export const useSendBookingEmail = () => {
  return useMutation(async (values: Record<any, unknown>) => {
    const res = await arkivetApi.post(`/v1/public/booking/${values.projectId}/sendInvite`, {
      ...values,
    })
    return res.data
  })
}

/**
 * useMutationTyping (success, error, input, unknown)
 */
export const useGetProjects = () => {
  return useQuery<any[], AxiosError>(
    ['all_projects'],
    async () => {
      const { data } = await arkivetApi.get<any[]>('/v1/projects')

      const mappedData = data.map((project) => ({
        title: project.title,
        subTitle: project.address,
        id: project.id,
      }))
      return mappedData
    },
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  )
}

export type Meeting = {
  id: string,
  updatedAt: string,
  createdAt: string,
  status: string,
  beginTime: string,
  endTime?: string,
  title: string,
  description?: string,
  type: string,
  url?: string,
  projectId: string,
}

export type Room = {
  id?: string;
  label: string;
}

export type Style = {
  id: string;
  name?: string;
  label?: string;
  value?: string;
}

export type APIProject = {
  id?: string,
  status?: string,
  title?: string,
  designerId?: string,
  designerEmail?: string,
  customerId?: string,
  customerEmail?: string,
  description?: string,
  placementIds?: string,
  postalCode?: string,
  initialMeetingType?: string,
  available?: string,
  meetings?: Meeting[],
  styles?: Style[],
  rooms?: Room[],
  budget?: string,
  address?: string,
  helpType?: string,
  createdAt?: Date,
  updatedAt?: Date,
}

export const useGetProjectById = (id?: string) => {
  return useQuery<APIProject | null, AxiosError<any>>(
    ['project_by_id'],
    async () => {
      const res = await arkivetApi.get<APIProject | null>(`/v1/project/${id}`)

      return res.data
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  )
}

export const useUpdateProject = (id?: string) => {
  return useMutation(async (values: {[T in keyof APIProject]: APIProject[T]}) => {
    const res = await arkivetApi.put(`/v1/project/${id}`, {
      ...values,
    })
    return res.data
  })
}

export type Upload = {
  link: string;
  photoCredit?: string;
}

export type OutgoingMessage = {
  body?: string;
  attatchments?: Upload;
  paymentIds: string[];
  composer: string;
};

export type IncomingMessage = {
  body?: string;
  createdAt: string;
  updatedAt: string;
  id: string;
  projectId: string;
  type: string;
  attatchments?: Upload;
  user: User
};

export const useAddMessage = (projectId?: string) => {
  return useMutation(async (message: OutgoingMessage) => {
    const res = await arkivetApi.post<Promise<{ id: string }>>(`/v1/project/${projectId}/message`, {
      ...message,
    })
    return res.data
  })
}

export const useGetMessagesByProjectId = (projectId?: string) => {
  return useQuery<IncomingMessage[] | null, AxiosError>(
    ['chats_by_project_id'],
    async () => {
      const res = await arkivetApi.get<IncomingMessage[] | null>(`/v1/project/${projectId}/message`)

      return res.data
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!projectId,
    }
  )
}
