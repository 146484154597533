import { breakpoints } from './breakpoints'

// Default values
export const theme = {
  breakpoints,
  space: [0, 4, 8, 16, 24, 32, 40, 48, 64, 96, 128, 256, 512],
  fonts: {
    serif: "'Space Grotesk', sans-serif",
    sans: "'Space Grotesk', sans-serif",
    mono: 'Menlo, monospace',
  },
  fontSizes: [11, 13, 16, 19, 21, 24, 30, 36, 50, 65, 80],
  fontWeights: {
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.6,
  },
  colors: {
    gray: '#E9EBEB',
    midGray: '#6D757D',
    graphite: '#434749',
    error: '#D81921',
    red: '#FF6A65',
    lightRed: '#FFF1F0',
    green: '#337B67',
    darkGreen: '#00695C',
    black: '#1C1D1E',
    lightGray: '#D6DBE0',
    lightBlue: '#E8F7FE',
    lightestGray: '#FAFAFA',
    lightGreen: '#DDF9EE',
    actionBlue: '#298EE8',
    uberlightGray: '#F1F2F3',
    uberlightPurple: '#fee9fa',
    lightYellow: '#FCF2D8',
  },
  radii: [0, 2, 4, 6, 8],

  // buttons: {
  //   primary: {
  //     color: '#fff',
  //     backgroundColor: '#298EE8'
  //   },
  //   default: {
  //     color: '#1C1D1E',
  //     border: '1px solid #e9ebeb',
  //     borderBottom: '1px solid #e1e3e3',
  //     backgroundColor: '#FFF',
  //     boxShadow: '0 2px 4px 0 rgba(14,30,37,.12)',
  //     fontWeight: 500,
  //     borderRadius: '4px'
  //   },
  //   secondary: {
  //     color: '#FF6A65',
  //     backgroundColor: '#FFF',
  //     border: '1px solid #FF6A65'
  //   }
  // }
}
