import React from 'react'
import { Text } from 'rebass'
import get from 'lodash.get'

const FieldError = ({ errors, name }) => {
  const fieldError = get(errors, name)
  if (fieldError) {
    return <Text my={0} as='p' color='error'>{fieldError}</Text>
  }
  return null
}
export default FieldError
