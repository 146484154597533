import React from 'react'
import { Text } from 'rebass'
// import { media } from '../../../constants/theme/breakpoints'

// 0: 11
// 1: 13
// 2: 16
// 3: 19
// 4: 21
// 5: 24
// 6: 30
// 7: 36
// 8: 50
// 9: 65
// 10: 80

const HeadingBase = props => <Text {...props}>{props.children}</Text>
HeadingBase.defaultProps = {
  lineHeight: 'title',
  fontWeight: 'bold',
  fontFamily: 'sans'
}

const TextBase = props => <Text {...props}>{props.children}</Text>
TextBase.defaultProps = {
  lineHeight: 'copy',
  fontWeight: 'regular'
}

export const HeadingMega = props => (
  <HeadingBase {...props} fontSize={[7, 8, 9, 10]}>
    {props.children}
  </HeadingBase>
)
HeadingMega.defaultProps = {
  lineHeight: 'solid',
  as: 'h1',
  letterSpacing: '-0.5px',
  fontWeight: 'bold'
}

export const Heading1 = props => (
  <HeadingBase {...props} fontSize={[7, 9]}>
    {props.children}
  </HeadingBase>
)
Heading1.defaultProps = {
  as: 'h1'
}

export const Heading2 = props => (
  <HeadingBase {...props} fontSize={[6, 8]}>
    {props.children}
  </HeadingBase>
)
Heading2.defaultProps = {
  as: 'h2',
  fontWeight: '600'
}

export const Heading3 = props => (
  <HeadingBase {...props} fontSize={[5, 7]}>
    {props.children}
  </HeadingBase>
)
Heading3.defaultProps = {
  as: 'h3',
  fontWeight: '600'
}

export const Heading4 = props => (
  <HeadingBase {...props} fontSize={[3, 4]}>
    {props.children}
  </HeadingBase>
)

Heading4.defaultProps = {
  as: 'h4',
  fontWeight: '600',
  letterSpacing: '-0.5px'
}

export const Lead = props => (
  <TextBase {...props} fontSize={[3, 4]} lineHeight="title">
    {props.children}
  </TextBase>
)
Lead.defaultProps = {
  as: 'p',
  color: 'midGray'
}

export const TextBody = props => (
  <TextBase {...props} fontSize={[2, 3]}>
    {props.children}
  </TextBase>
)
TextBody.defaultProps = {
  as: 'p',
  style: { whiteSpace: 'pre-wrap' }
}

export const TextSmall = props => (
  <TextBase fontSize={[2, 2]} {...props}>
    {props.children}
  </TextBase>
)
TextSmall.defaultProps = {
  as: 'p'
}

export const TextMeta = props => (
  <TextBase {...props} fontSize={[0, 0]} letterSpacing={0.77}>
    {props.children}
  </TextBase>
)

TextMeta.defaultProps = {
  as: 'p',
  style: { textTransform: 'uppercase' }
}
