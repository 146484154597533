import React, { FC } from 'react'
import styled from 'styled-components'
import Container from '../../../components/Container'
import { useBookingStore } from '../../../services/store/useBookingStore'
import { HELP_TYPE } from '../data/helpType'
import { navigate } from '@reach/router'

type HelpComponentTypeType = {
  slug: string,
}

export const HelpTypeComponent: FC<HelpComponentTypeType> = ({ slug }) => {
  const { clearForm, setStep, updateForm } = useBookingStore()
  const helpType = HELP_TYPE.find((item) => item.slug === slug)
  if (!helpType) return null
  return (
    <Container>
      <StyledHelpType>
        <h1>{helpType.title}</h1>
        <h2>{helpType.description}</h2>
        <img src={helpType.image} alt={helpType.title} />
        <div className="info-container">
          <div className="info">
            <p>{helpType.readMore.description}</p>
            {helpType.readMore.urlTitle && (
              <a href={helpType.readMore.url}>{helpType.readMore.urlTitle}</a>
            )}
            {helpType.readMore.prices && (
              <ul>
                <li>Pris:</li>
                {helpType.readMore.prices.map((price) => (
                  <li key={price}>{price}</li>
                ))}
              </ul>
            )}
          </div>
          <div className="action">
            <p>Prisantydning</p>
            <p className="price-range">{helpType.priceRange}</p>
            <button
              onClick={() => {
                clearForm()
                updateForm('helpType', slug)
                setStep(2)
                navigate('/booking')
              }}
            >
              Velg
            </button>
          </div>
        </div>
      </StyledHelpType>
    </Container>
  )
}

const StyledHelpType = styled.section`
  h1 {
    font-size: 2.2rem;
    font-weight: 600;
    color: #0000f2;
    text-align: center;
  }
  h2 {
    width: 70%;
    margin: 20px auto;
    font-size: 1.2rem;
    text-align: center;
    @media screen and (max-width: 800px) {
      width: 90%;
    }
  }
  img {
    max-height: 50vh;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
  }
  .info-container {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 40px;
    .info {
      p {
        margin: 10px 0 20px 0;
        line-height: 30px;
      }
      a {
        color: #0000f2;
        font-weight: 600;
        text-decoration: underline;
        cursor: pointer;
      }
      ul {
        list-style: none;
        padding: 0;
        li {
          line-height: 50px;
        }
      }
    }
    .action {
      height: fit-content;
      border: 1px solid #dfe2e7;
      border-radius: 4px;
      padding: 20px;
      p:first-of-type {
        font-size: 1.1rem;
        font-weight: 700;
      }
      .price-range {
        margin: 10px 0;
      }
      button {
        cursor: pointer;
        margin-top: 8px;
        background-color: #0000f2;
        padding: 10px 20px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        color: white;
      }
    }
    @media screen and (max-width: 800px) {
      grid-template-columns: 1fr;
    }
  }
`
