import React from 'react'
import { Text } from 'rebass'

const Error = ({ children }: { children?: any }) => (
  <Text my={0} as="p" color="error">
    {children}
  </Text>
)

export default Error
