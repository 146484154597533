import React from 'react'
import { Field } from 'formik'
import { Box, Text, Card, Image, Flex } from 'rebass'

const Checkbox = ({ parent, name, label, values, image, type }) => {
  const checked = values[parent][name] !== undefined && values[parent][name]
  return (
    <Box width={[1 / 2, 1 / 3]}>
      <label htmlFor={name} data-testid={`checkbox-${name}`}>
        {type === 'photo' ? (
          <Card
            style={{
              cursor: 'pointer',
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              textShadow: '0 0 10px rgba(0,0,0,1)',
            }}
            flexDirection="column"
            alignItems="center"
            as={Flex}
            borderRadius={6}
            border={checked ? '4px solid' : 'none'}
            borderColor={checked ? 'actionBlue' : 'transparent'}
            p={8}
            m={2}
          >
            <Text fontSize={3} color="white" textAlign="center" fontWeight="semibold">
              {label}
            </Text>
          </Card>
        ) : (
          <Card
            style={{ cursor: 'pointer' }}
            flexDirection="column"
            alignItems="center"
            as={Flex}
            borderRadius={6}
            bg={checked ? 'actionBlue' : 'lightBlue'}
            p={4}
            m={2}
          >
            {image && (
              <Image my={3} style={{ height: '50px', margin: '16px 0px 16px 0px' }} src={image} />
            )}
            <Text color={checked ? 'white' : 'black'} textAlign="center" fontWeight="semibold">
              {label}
            </Text>
          </Card>
        )}
      </label>
      <Field
        style={{ display: 'none' }}
        id={name}
        component="input"
        type="checkbox"
        name={`['${parent}'].['${name}']`}
        checked={checked}
      />
    </Box>
  )
}

export default Checkbox
