export enum Env {
  Localhost = 'localhost',
  Development = 'development',
  Production = 'production',
}

export const resolveEnv = () => {
  const override = process.env.REACT_APP_CURRENT_ENV

  if (override === Env.Localhost || override === Env.Development || override === Env.Production) {
    return override
  }

  const hostname = window.location.hostname
  if (hostname?.endsWith('test.iterate.no')) {
    return Env.Development
  } else if (hostname === 'localhost') {
    return Env.Localhost
  } else return Env.Production
}

export const isProduction = () => resolveEnv() === Env.Production
