import React from 'react'
import styled from 'styled-components'
import { Field } from 'formik'

const StyledInput = styled(Field)`
  transition: all .2s;
  font-size: inherit;
  line-height: ${props => props.theme.lineHeights.copy};
  padding: 14px 12px;
  margin: 8px 0px;
  width: 100%;
  border-radius: 4px;
  color: inherit;
  font-weight: ${props => props.theme.fontWeights.medium};
  /* background-color: #461d1b08; */
  font-family: inherit;
  /* display: inline-block; */
  vertical-align: middle;
  appearance: none;
  box-shadow: ${props => props.theme.colors.midGray} 0px 0px 0px 1px inset;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px ${props => props.theme.colors.actionBlue};
  }
  &:disabled {
    opacity: 0.25;
  }
`

const Input = props => <StyledInput {...props} />

export default Input
