import TagManager from 'react-gtm-module'
import React, { Fragment, Suspense } from 'react'
import ReactDOM from 'react-dom'
import Loading from './components/Loading'
import { Provider } from 'react-redux'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { StripeProvider, Elements } from 'react-stripe-elements'
import configureStore from './store'
import '@reach/dialog/styles.css'
import { theme } from './constants/theme'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { isProduction, resolveEnv } from './utils/url'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

const tagManagerArgs = {
  gtmId: 'GTM-WND83FL',
  auth: isProduction() ? process.env.REACT_APP_GTM_AUTH_PROD : process.env.REACT_APP_GTM_AUTH_DEV,
  preview: isProduction() ? process.env.REACT_APP_GTM_ENV_PROD : process.env.REACT_APP_GTM_ENV_DEV,
}

TagManager.initialize(tagManagerArgs)

const initialState = window.__INITIAL_STATE__ || {}
const store = configureStore(initialState)
const GlobalStyles = createGlobalStyle`html,body,p,ol,ul,li,dl,dt,dd,blockquote,figure,fieldset,legend,textarea,pre,iframe,hr,h1,h2,h3,h4,h5,h6{margin:0;padding:0}h1,h2,h3,h4,h5,h6{font-size:100%;font-weight:normal}ul{list-style:none}button,input,select,textarea{margin:0}html{box-sizing:border-box}*,*:before,*:after{box-sizing:inherit}img,embed,iframe,object,audio,video{height:auto;max-width:100%}iframe{border:0}table{border-collapse:collapse;border-spacing:0}td,th{padding:0;text-align:left}

  body {
    margin: 0;
    font-family: "Space Grotesk", system-ui, sans-serif;
    font-size: 16px;
    font-weight: normal;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;


  }

  a {
    color: #298EE8;
  }

  .ul-level-0, .ol-level-0 {
    list-style-type: disc;
  }

  .ul-level-1, .ol-level-1 {
    list-style-type: circle;
  }

  .ul-level-2, .ol-level-2 {
    list-style-type: square;
  }



  #home-page .slider-control-centerright {
    top: 30% !important;
    right: -15px !important;
    transform: translateY(-30%) !important;
  }

  #home-page .slider-control-centerleft {
    top: 30% !important;
    left: -15px !important;
    transform: translateY(-30%) !important;
  }
`

const env = resolveEnv()

Sentry.init({
  environment: env,
  dsn: 'https://702397278e2e48a18593d50ab9ff6a97@o402078.ingest.sentry.io/4504485258592256',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1, //TODO when we are getting lots of events this should be reduced
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
  ],
})

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Fragment>
        <GlobalStyles />
        <StripeProvider
          apiKey={
            isProduction()
              ? process.env.REACT_APP_STRIPE_API_KEY_PROD
              : process.env.REACT_APP_STRIPE_API_KEY_DEV
          }
        >
          <Elements>
            <Suspense fallback={<Loading />}>
              <App />
            </Suspense>
          </Elements>
        </StripeProvider>
      </Fragment>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
