export type HelpTypeType = {
  image: string,
  title: string,
  description: string,
  priceRange: string,
  slug: string,
  readMore: {
    description: string,
    url?: string,
    urlTitle?: string,
    prices?: string[],
  },
}

export const HELP_TYPE: HelpTypeType[] = [
  {
    image: 'https://www.arkivet.co/images/SPARRING_FIRKANT.png',
    title: 'Sparringstime',
    description:
      'Har du allerede noen tanker og ideer, men trenger en sparringspartner for å lande de riktige avgjørelsene?',
    priceRange: '2 000 kr',
    slug: 'sparringstime',
    readMore: {
      description:
        'Interiørarkitekten leser seg opp på ditt prosjekt og dine utfordringer i forkant. 60 min møte med sparring og svar på dine spørsmål.',
      url: 'https://blogg.arkivet.co/sparringstime-fa-tips-og-inspirasjonen-du-trenger/',
      urlTitle: 'Les mer om sparringstimen her',
      prices: ['2000,- Videomøte', '4000,- Hjemme hos deg (I Stor Oslo)'],
    },
  },
  {
    image: 'https://www.arkivet.co/images/MOBLER_DEKOR_FIRKANT.png',
    title: 'Stue, entre og soverom',
    description:
      'Trenger du nye møbler, dekor, dekorbelysning og/eller hjelp til farge og materialvalg til ett eller flere rom?',
    priceRange: '4 500 - 25 000 kr',
    slug: 'stue-entre-og-soverom',
    readMore: {
      description: `Starte med en gratis oppstartsamtale med en av designerne i Arkivet. Interiørdesigneren tar kontakt for å høre mer om prosjektet deretter gis et tilbud.\n
Etter godkjennelse av tilbud utarbeides det en kollasj som vil være med på å gi deg et helhetlig inntrykk av resultatet.\n
- 45 min videomøte med forklaring av forslag og valg til ditt hjem.\n
- Møbleringsplan (2D) med plassering av møbler, dekor og dekorbelysning.\n
- Innkjøpsliste med linker for enkel handel.\n
- En stk. eventuell revisjon etter videomøte.\n
- Tilsendt kollasj i PDF i etterkant av møtet.`,
      url: 'https://blogg.arkivet.co/forny-ditt-hjem-med-nye-mobler-dekor-og-belysning/',
      urlTitle: 'Les mer og se eksempel på en leveranse her',
      prices: [
        'Oppstartssone 4 500 kr',
        'Ekstra sone 2 500 kr',
        'Farger per sone 1 500 kr',
        'Fysisk befaring 2 500 kr (kun Oslo og Bærum)',
      ],
    },
  },
  {
    image: 'https://www.arkivet.co/images/BAD_FIRKANT.png',
    title: 'Baderom',
    description:
      'Skal du bygge bad og trenger hjelp til fliser, materialer, spesialdesign, tekniske tegninger til håndtverker?',
    priceRange: '5 000 - 50 000 kr',
    slug: 'baderom',
    readMore: {
      description: `Vi setter deg i kontakt med en interiørdesigner som har kompetanse på bad og kan være med å realisere din baderomsdrøm. Etter du har sendt inn forespørsel, vil din valgte Interiørdesigner ta kontakt for å høre mer om prosjektet og gi et uforpliktende tilbud.`,
      url: 'https://blogg.arkivet.co/oppussing-av-litet-bad/',
      urlTitle: 'Les mer og se eksempel på en leveranse her',
    },
  },
  {
    image: 'https://www.arkivet.co/images/KJOKKEN_FIRKANT.png',
    title: 'Kjøkken',
    slug: 'kjokken',
    description:
      'Skal du bygge kjøkken og trenger spesialdesign, veiledning på materialvalg eller innspill på tegninger fra kjøkkenforhandler?',
    priceRange: '5 000 - 50 000 kr',
    readMore: {
      description: `Vi setter deg i kontakt med en interiørdesigner som har kompetanse på kjøkken og kan være med å realisere din kjøkkendrøm. Etter du har sendt inn forespørsel, vil din valgte Interiørdesigner ta kontakt for å høre mer om prosjektet og gi et uforpliktende tilbud.`,
    },
  },
  {
    image: 'https://www.arkivet.co/images/TOTALPROSJEKT_FIRKANT.png',
    title: 'Totalprosjekt',
    description: 'Skal du rehabilitere et helt hus/leilighet?',
    priceRange: '15 000 - 150 000 kr',
    slug: 'totalprosjekt',
    readMore: {
      description: `Ønsker du å få laget en god møbleringsplan, belysningsplan, fargeangivelsesplan, valg av materialer til faste flater som gulv, vegger, tak, benkeplater, dører/listverk o.l. Kanskje spesialdesignede møbler og/eller løst inventar som møbler, belysning og øvrig dekor? Vi setter deg i kontakt med en Interiørarkitekt som har kompetanse på totalprosjekt. Interiørarkitekten som du velger, vil etter å ha mottatt din forespørsel ta kontakt for å høre mer om prosjektet og gi et uforpliktende tilbud.`,
      urlTitle: 'Les mer og se eksempel på en leveranse her',
      url: 'https://blogg.arkivet.co/interiorprosjekt-oppussing-av-leilighet/',
    },
  },
  {
    image: 'https://www.arkivet.co/images/NYBYGG_FIRKANT.png',
    title: 'Nybygg',
    description: 'Skal du bygge hys, hytte eller har du kjøp en leilighet som ennå ikke er bygget?',
    priceRange: '10 000 - 100 000 kr',
    slug: 'nybygg',
    readMore: {
      description: `Vi setter deg i kontakt med en interiørarkitekt som kan hjelpe deg med tilvalgsfasen, belysningsplan, møbleringsplan, spesialdesign av garderober og annen oppbevaring samt få en rød tråd i prosjektet med farger, tekstiler og møbler. Interiørarkitekten som du velger, vil etter å ha mottatt din forespørsel ta kontakt for å høre mer om prosjektet og gi et uforpliktende tilbud.`,
    },
  },
  {
    image: 'https://www.arkivet.co/images/SPESIALDESIGN_FIRKANT.png',
    title: 'Spesialdesign',
    description: 'Trenger du hjelp med å få optimalisere oppbevaringen i ditt hjem?',
    priceRange: '10 000 - 100 000 kr',
    slug: 'spesialdesign',
    readMore: {
      description: `Vi setter deg i kontakt med en interiørarkitekt som kan spesialtegne bokhyller, garderober, baderomsinnredning, sittebenk etc. Interiørarkitekten som du velger, vil etter å ha mottatt din forespørsel ta kontakt for å høre mer om prosjektet og gi et uforpliktende tilbud.`,
    },
  },
  {
    image: 'https://www.arkivet.co/images/BELYSNINGSDESIGN_FIRKANT.png',
    title: 'Belysningsdesign',
    description: 'Trenger du hjelp til teknisk belysning og elplan?',
    priceRange: '10 000 - 50 000 kr',
    slug: 'belysningsdesign',
    readMore: {
      description: `Vi setter deg i kontakt med en interiørarkitekt eller lysdesigner som kan hjelpe deg med å planlegge ny belysningsplan, finne riktige lamper til ulikt behov og evtuelle tekniske tegninger som trengs. Interiørarkitekten som du velger, vil etter å ha mottatt din forespørsel ta kontakt for å høre mer om prosjektet og gi et uforpliktende tilbud.`,
    },
  },
  {
    image: 'https://www.arkivet.co/images/STYLING_FIRKANT.png',
    title: 'Styling',
    description: 'Trenger du hjelp med siste finish?',
    priceRange: '5 000 - 50 000 kr',
    slug: 'styling',
    readMore: {
      description: `Vi hjelper deg finne en interiørdesignere som kan hjelpe deg med å få de siste detaljene på plass, slik at du oppnår wow effekten i ditt hjem. Detaljene er vel så viktige i ditt nåværende hjem, som ved salg av bolig. Etter å ha mottatt din forespørsel, vil Interiørdesigneren ta kontakt for å høre mer om prosjektet og gi et uforpliktende tilbud.`,
    },
  },
  {
    image: 'https://www.arkivet.co/images/SPARRING_FIRKANT.png',
    title: 'Kunstrådgivning',
    description:
      'Har du kunst som du ønsker hjelp med plassering i ditt hjem. Trenger du komplettere med ny kunst? Arkivet tilbyr kunstrådgivning med flinke eksperter.',
    priceRange: '900 - 3 000 kr',
    slug: 'kunstradgivning',
    readMore: {
      description: 'Les mer her om hva en kunstrådgiver kan hjelpe deg med.',
      url: 'https://blogg.arkivet.co/personlig-kunstradgivning/',
      urlTitle: 'Personlig kunstrådgivning',
      prices: [
        'Kunstrådgivning videokonsultasjon 900 kr inkl mva. Book en kunstprat for å få anbefalinger til hvordan du bør gå frem for å finne kunst,  basert på dine ønsker og behov.',
        'Privat kuratering videokonsultasjon 3 000 kr inkl mva. Få hjelp til hvilke verk som bør kjøpes inn, hvor de bør henge og tips til opphenging. Dette gjøres også på bakgrunn av verk som allerede er i eie. Prisen vil justeres etter behov.',
      ],
    },
  },
  {
    image: 'https://www.arkivet.co/images/UTEROM_FIRKANT.png',
    title: 'Uterom',
    description:
      'Trenger du hjelp med møbler og dekor for å skape sosiale soner i hagen eller på din uteplass?',
    priceRange: `Oppstartssone 4 500 kr\n
                Ekstra sone 2 500 kr\n
                Fysisk befaring 2 500 kr\n
                (kun Oslo og Bærum)`,
    slug: 'uterom',
    readMore: {
      description: `Interiørdesigneren tar kontakt for å høre mer om prosjektet.\n
- Det utarbeides en kollasj med forslag basert på dine ønsker.\n
- 45 min videomøte med forklaring av forslag og valg til ditt hjem.\n
- En stykk eventuell revisjon etter videomøte.\n
- Møbleringsplan (2D) med plassering av produkter og linket direkte til nettbutikk for enkel handel.`,
    },
  },
  {
    image: 'https://www.arkivet.co/images/HAGEDESIGN_FIRKANT.png',
    title: 'Hagedesign',
    description: 'Skal du rehabilitere en hage?',
    priceRange: '10 000 kr - 100 000 kr',
    slug: 'hagedesign',
    readMore: {
      description: `Vi setter deg i kontakt med en hagedesigner som kan designe din drømmehage og komme med forslag på passende planter. Hagedesigneren som du velger, vil etter å ha mottatt din forespørsel ta kontakt for å høre mer om prosjektet og gi et uforpliktende tilbud.`,
    },
  },
]
