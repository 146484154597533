import React, { FC, useState } from 'react'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import styled from 'styled-components'
import { useUpdateBooking } from '../../../../services/api/useProject'
import { useBookingStore } from '../../../../services/store/useBookingStore'
import { eachWeekOfInterval, startOfWeek, endOfWeek, format, getISOWeek } from 'date-fns'
import { SpinnerLoader } from '../SpinnerLoader'
import Spinner from '../../../../components/Spinner'

const currentYear = new Date().getFullYear().toString()
const startOfYear = new Date(Number(currentYear), 0, 1)
const endOfYear = new Date(Number(currentYear), 11, 31)

const nextYear = (new Date().getFullYear() + 1).toString()
const startOfNextYear = new Date(Number(nextYear), 0, 1)
const endOfNextYear = new Date(Number(nextYear), 11, 31)

const weeks = eachWeekOfInterval({ start: startOfYear, end: endOfYear }).slice(1)
let weeksNextYear = eachWeekOfInterval({ start: startOfNextYear, end: endOfNextYear }).slice(1)

const weeksWithDates = weeks.map((week) => {
  const startDate = startOfWeek(week, {
    locale: {
      options: { weekStartsOn: 1 },
    },
  })
  const endDate = endOfWeek(week, {
    locale: {
      options: { weekStartsOn: 1 },
    },
  })
  const formattedStartDate = format(startDate, 'dd. MMM')
  const formattedEndDate = format(endDate, 'dd. MMM')
  return { startDate: formattedStartDate, endDate: formattedEndDate, weekNumber: getISOWeek(week) }
})

const remainingWeeksThisYear = weeksWithDates.filter(
  ({ weekNumber }) => Number(weekNumber) >= getISOWeek(new Date())
)

//A bit haacky but this ensure that we can include next years bookings without having to specify which year they belong to. We remove all weeks that are
//left of this year from next year.
weeksNextYear = weeksNextYear.slice(0, -remainingWeeksThisYear.length)

const weeksWithDatesNextYear = weeksNextYear.map((week) => {
  const startDate = startOfWeek(week, {
    locale: {
      options: { weekStartsOn: 1 },
    },
  })
  const endDate = endOfWeek(week, {
    locale: {
      options: { weekStartsOn: 1 },
    },
  })
  const formattedStartDate = format(startDate, 'dd. MMM')
  const formattedEndDate = format(endDate, 'dd. MMM')
  return { startDate: formattedStartDate, endDate: formattedEndDate, weekNumber: getISOWeek(week) }
})

const Step4: FC<{ firestore?: any }> = ({ firestore }) => {
  const updateProject = useUpdateBooking()
  const { incrementStep, setStep, updateForm, createdProjectId, preSelectedDesigner, form } =
    useBookingStore()

    const [loading, setLoading] = useState(false)

  const nextStep = async (selectedAvailability: string) => {
    updateForm('selectedAvailability', selectedAvailability)
    setLoading(true);
    await updateProject.mutateAsync({
      createdProjectId,
      selectedAvailability,
    })

    if (preSelectedDesigner) {
      //Think this should be removed?
      // insertIntoFirebase({...form, selectedAvailability }, firestore, form.designer!.firebaseDesignerId)
      if (form.designer?.firebaseDesignerId === 'AOvb6yGI10ezhMbzLpNKMxLwdCW2') {
        //Hvis Nina/bedrift
        updateForm('helpType', 'bedrift')
        setLoading(false);
        setStep(6)
        return
      }
      setLoading(false);
      setStep(6)
    } else {
      setLoading(false);
      incrementStep()
    }

    window.dataLayer.push({
      event: 'b_complete_selected_week',
      bookingSelectedWeek: selectedAvailability,
      ...(preSelectedDesigner
        ? {
            preSelectedDesigner: true,
            selectedDesigner: form.designer?.firebaseDesignerId + ' - ' + form.designer?.name,
          }
        : { preSelectedDesigner: false }),
    })
  }

  if(loading) {
    return <SpinnerWrapper><SpinnerLoader /></SpinnerWrapper>
  }

  return (
    <div>
      <StyledH2>{currentYear}</StyledH2>
      <StyledStep4>
        {weeksWithDates
          .filter(({ weekNumber }) => Number(weekNumber) >= getISOWeek(new Date()) && preSelectedDesigner ? form.designer?.availability.includes(`${weekNumber}-${currentYear.slice(-2)}`) : true)
          .sort((a, b) => a.weekNumber - b.weekNumber)
          .map((item) => {
            return (
              <button
                disabled={loading}
                onClick={() => {
                  nextStep(`${item.weekNumber}-${currentYear.slice(-2)}`)
                }}
                key={item.startDate}
              >
                <p>{`Uke ${item.weekNumber}`}</p>
                <p>{`${item.startDate} - ${item.endDate.toLowerCase()}`}</p>
              </button>
            )
          })}
      </StyledStep4>
      <StyledH2>{nextYear}</StyledH2>
      <StyledStep4>
        {weeksWithDatesNextYear
          .filter(({ weekNumber }) => Number(weekNumber) >= getISOWeek(startOfNextYear) && preSelectedDesigner ? form.designer?.availability.includes(`${weekNumber}-${nextYear.slice(-2)}`) : true)
          .sort((a, b) => a.weekNumber - b.weekNumber)
          .map((item) => {
            return (
              <button
                disabled={loading}
                onClick={() => {
                  nextStep(`${item.weekNumber}-${nextYear.slice(-2)}`)
                }}
                key={item.startDate}
              >
                <p>{`Uke ${item.weekNumber}`}</p>
                <p>{`${item.startDate} - ${item.endDate.toLowerCase()}`}</p>
              </button>
            )
          })}
      </StyledStep4>
    </div>
  )
}

export default compose(firestoreConnect())(Step4)


const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const StyledH2 = styled.h2`
  padding: 20px 0;
`

const StyledStep4 = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  button {
    font-size: 1.1rem;
    cursor: pointer;
    text-align: left;
    background-color: inherit;
    border: 2px solid #c8ccd2;
    border-radius: 4px;
    padding: 20px;
    p:first-of-type {
      font-weight: 600;
      margin-bottom: 8px;
    }
    p:last-of-type {
      font-size: 1rem;
    }
  }
`
