import React from 'react'
import { Card, Text, Flex } from 'rebass'

const Error = ({ children }) => {
  return (
    <Flex height={'100vh'} alignItems="center" justifyContent="center">
      <Card width={1 / 1} p={4} m={4} bg={'red'}>
        <Text as="h1">{children}</Text>
      </Card>
    </Flex>
  )
}

export default Error
