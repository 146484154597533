import React from 'react'
import styled from 'styled-components'
import { Field } from 'formik'

const StyledInput = styled(Field)`
  transition: all .2s;
  font-size: inherit;
  line-height: ${props => props.theme.lineHeights.copy};
  line-height: 1;
  padding-left: ${props => (props.size === 'small' ? props.theme.space[3] : props.theme.space[4])}px;
  padding-right: ${props => (props.size === 'small' ? props.theme.space[3] : props.theme.space[4])}px;
  padding-top: ${props => (props.size === 'small' ? props.theme.space[2] : props.theme.space[3])}px;
  padding-bottom: ${props => (props.size === 'small' ? props.theme.space[2] : props.theme.space[3])}px;
  width: 100%;
  border-radius: 4px 0px 0px 4px;
  color: white;
  font-weight: ${props => props.theme.fontWeights.medium};
  background-color:  ${props => props.theme.colors.black};
  font-family: inherit;
  /* display: inline-block; */
  height: 50px;
  vertical-align: middle;
  appearance: none;
  border: 1px solid ${props => props.theme.colors.midGray};
  border-right: 0px;
  /* box-shadow: ${props => props.theme.colors.midGray} 0px 0px 0px 1px inset; */
  /* border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial; */
  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px ${props => props.theme.colors.actionBlue};
  }
  &:disabled {
    opacity: 0.25;
  }
`

const NewsLetterInput = props => <StyledInput {...props} />

export default NewsLetterInput
