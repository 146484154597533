import React, { Component, createContext } from 'react'

const AuthContext = createContext({})

class AuthProvider extends Component {
  state = {
    user: {},
  }

  setUser = (user) => {
    this.setState(() => ({ user }))
  }

  async componentDidMount() {
    // TODO: Uncomment when we backend handles auth
    // const { data } = await getSession()
    // this.setState(() => ({ user: data }))
  }

  render() {
    const { children } = this.props
    const { user } = this.state
    const { setUser } = this

    return (
      <AuthContext.Provider value={{ user, setUser, isLoggedIn: !!user.id }}>
        {children}
      </AuthContext.Provider>
    )
  }
}

export default AuthContext

export { AuthProvider }
