export const POSTAL_CODES = [
  '1001',
  '1003',
  '1005',
  '1006',
  '1007',
  '1008',
  '1009',
  '1011',
  '1051',
  '1052',
  '1053',
  '1054',
  '1055',
  '1056',
  '1061',
  '1062',
  '1063',
  '1064',
  '1065',
  '1067',
  '1068',
  '1069',
  '1071',
  '1081',
  '1083',
  '1084',
  '1086',
  '1087',
  '1088',
  '1089',
  '1101',
  '1102',
  '1108',
  '1109',
  '1112',
  '1150',
  '1151',
  '1152',
  '1153',
  '1154',
  '1155',
  '1156',
  '1157',
  '1158',
  '1160',
  '1161',
  '1162',
  '1163',
  '1164',
  '1165',
  '1166',
  '1167',
  '1168',
  '1169',
  '1170',
  '1172',
  '1176',
  '1177',
  '1178',
  '1179',
  '1181',
  '1182',
  '1184',
  '1185',
  '1187',
  '1188',
  '1189',
  '1201',
  '1203',
  '1204',
  '1205',
  '1207',
  '1214',
  '1215',
  '1250',
  '1251',
  '1252',
  '1253',
  '1254',
  '1255',
  '1256',
  '1257',
  '1258',
  '1259',
  '1262',
  '1263',
  '1266',
  '1270',
  '1271',
  '1272',
  '1273',
  '1274',
  '1275',
  '1278',
  '1279',
  '1281',
  '1283',
  '1284',
  '1285',
  '1286',
  '1290',
  '1291',
  '1294',
  '1295',
  '1300',
  '1301',
  '1302',
  '1303',
  '1304',
  '1305',
  '1306',
  '1307',
  '1308',
  '1309',
  '1311',
  '1312',
  '1313',
  '1314',
  '1316',
  '1317',
  '1318',
  '1319',
  '1321',
  '1322',
  '1323',
  '1324',
  '1325',
  '1326',
  '1327',
  '1328',
  '1329',
  '1330',
  '1331',
  '1332',
  '1333',
  '1334',
  '1335',
  '1336',
  '1337',
  '1338',
  '1339',
  '1340',
  '1341',
  '1342',
  '1344',
  '1346',
  '1348',
  '1349',
  '1350',
  '1351',
  '1352',
  '1353',
  '1354',
  '1356',
  '1357',
  '1358',
  '1359',
  '1360',
  '1361',
  '1362',
  '1363',
  '1364',
  '1365',
  '1366',
  '1367',
  '1368',
  '1369',
  '1371',
  '1372',
  '1373',
  '1375',
  '1376',
  '1377',
  '1378',
  '1379',
  '1380',
  '1381',
  '1383',
  '1384',
  '1385',
  '1386',
  '1387',
  '1388',
  '1389',
  '1390',
  '1391',
  '1392',
  '1393',
  '1394',
  '1395',
  '1396',
  '1397',
  '1399',
  '0010',
  '0015',
  '0018',
  '0021',
  '0024',
  '0026',
  '0028',
  '0030',
  '0031',
  '0032',
  '0033',
  '0034',
  '0037',
  '0040',
  '0045',
  '0046',
  '0047',
  '0048',
  '0050',
  '0055',
  '0060',
  '0081',
  '0101',
  '0102',
  '0103',
  '0104',
  '0105',
  '0106',
  '0107',
  '0109',
  '0110',
  '0111',
  '0112',
  '0113',
  '0114',
  '0115',
  '0116',
  '0117',
  '0118',
  '0119',
  '0120',
  '0121',
  '0122',
  '0123',
  '0124',
  '0125',
  '0128',
  '0129',
  '0130',
  '0131',
  '0132',
  '0133',
  '0134',
  '0135',
  '0136',
  '0138',
  '0139',
  '0140',
  '0150',
  '0151',
  '0152',
  '0153',
  '0154',
  '0155',
  '0157',
  '0158',
  '0159',
  '0160',
  '0161',
  '0162',
  '0164',
  '0165',
  '0166',
  '0167',
  '0168',
  '0169',
  '0170',
  '0171',
  '0172',
  '0173',
  '0174',
  '0175',
  '0176',
  '0177',
  '0178',
  '0179',
  '0180',
  '0181',
  '0182',
  '0183',
  '0184',
  '0185',
  '0186',
  '0187',
  '0188',
  '0190',
  '0191',
  '0192',
  '0193',
  '0194',
  '0195',
  '0196',
  '0198',
  '0201',
  '0202',
  '0203',
  '0204',
  '0207',
  '0208',
  '0211',
  '0212',
  '0213',
  '0214',
  '0215',
  '0216',
  '0217',
  '0218',
  '0230',
  '0240',
  '0244',
  '0247',
  '0250',
  '0251',
  '0252',
  '0253',
  '0254',
  '0255',
  '0256',
  '0257',
  '0258',
  '0259',
  '0260',
  '0262',
  '0263',
  '0264',
  '0265',
  '0266',
  '0267',
  '0268',
  '0270',
  '0271',
  '0272',
  '0273',
  '0274',
  '0275',
  '0276',
  '0277',
  '0278',
  '0279',
  '0280',
  '0281',
  '0282',
  '0283',
  '0284',
  '0286',
  '0287',
  '0301',
  '0302',
  '0303',
  '0304',
  '0305',
  '0306',
  '0307',
  '0308',
  '0309',
  '0311',
  '0313',
  '0314',
  '0315',
  '0316',
  '0317',
  '0318',
  '0319',
  '0323',
  '0330',
  '0340',
  '0349',
  '0350',
  '0351',
  '0352',
  '0353',
  '0354',
  '0355',
  '0356',
  '0357',
  '0358',
  '0359',
  '0360',
  '0361',
  '0362',
  '0363',
  '0364',
  '0365',
  '0366',
  '0367',
  '0368',
  '0369',
  '0370',
  '0371',
  '0372',
  '0373',
  '0374',
  '0375',
  '0376',
  '0377',
  '0378',
  '0379',
  '0380',
  '0381',
  '0382',
  '0383',
  '0401',
  '0402',
  '0403',
  '0404',
  '0405',
  '0406',
  '0409',
  '0410',
  '0411',
  '0412',
  '0413',
  '0415',
  '0421',
  '0422',
  '0423',
  '0424',
  '0440',
  '0441',
  '0442',
  '0445',
  '0450',
  '0451',
  '0452',
  '0454',
  '0455',
  '0456',
  '0457',
  '0458',
  '0459',
  '0460',
  '0461',
  '0462',
  '0463',
  '0464',
  '0465',
  '0467',
  '0468',
  '0469',
  '0470',
  '0472',
  '0473',
  '0474',
  '0475',
  '0476',
  '0477',
  '0478',
  '0479',
  '0480',
  '0481',
  '0482',
  '0483',
  '0484',
  '0485',
  '0486',
  '0487',
  '0488',
  '0489',
  '0490',
  '0491',
  '0492',
  '0493',
  '0494',
  '0495',
  '0496',
  '0501',
  '0502',
  '0503',
  '0504',
  '0505',
  '0506',
  '0507',
  '0508',
  '0509',
  '0510',
  '0511',
  '0512',
  '0513',
  '0515',
  '0516',
  '0517',
  '0518',
  '0520',
  '0540',
  '0550',
  '0551',
  '0552',
  '0553',
  '0554',
  '0555',
  '0556',
  '0557',
  '0558',
  '0559',
  '0560',
  '0561',
  '0562',
  '0563',
  '0564',
  '0565',
  '0566',
  '0567',
  '0568',
  '0569',
  '0570',
  '0571',
  '0572',
  '0573',
  '0574',
  '0575',
  '0576',
  '0577',
  '0578',
  '0579',
  '0580',
  '0581',
  '0582',
  '0583',
  '0584',
  '0585',
  '0586',
  '0587',
  '0588',
  '0589',
  '0590',
  '0591',
  '0592',
  '0593',
  '0594',
  '0595',
  '0596',
  '0597',
  '0598',
  '0601',
  '0602',
  '0603',
  '0604',
  '0605',
  '0606',
  '0607',
  '0608',
  '0609',
  '0611',
  '0612',
  '0613',
  '0614',
  '0615',
  '0616',
  '0617',
  '0618',
  '0619',
  '0620',
  '0621',
  '0622',
  '0623',
  '0624',
  '0626',
  '0650',
  '0651',
  '0652',
  '0653',
  '0654',
  '0655',
  '0656',
  '0657',
  '0658',
  '0659',
  '0660',
  '0661',
  '0662',
  '0663',
  '0664',
  '0665',
  '0666',
  '0667',
  '0668',
  '0669',
  '0670',
  '0671',
  '0672',
  '0673',
  '0674',
  '0675',
  '0676',
  '0677',
  '0678',
  '0679',
  '0680',
  '0681',
  '0682',
  '0683',
  '0684',
  '0685',
  '0686',
  '0687',
  '0688',
  '0689',
  '0690',
  '0691',
  '0692',
  '0693',
  '0694',
  '0701',
  '0702',
  '0705',
  '0710',
  '0712',
  '0750',
  '0751',
  '0752',
  '0753',
  '0754',
  '0755',
  '0756',
  '0757',
  '0758',
  '0760',
  '0763',
  '0764',
  '0765',
  '0766',
  '0767',
  '0768',
  '0770',
  '0771',
  '0772',
  '0773',
  '0774',
  '0775',
  '0776',
  '0777',
  '0778',
  '0779',
  '0781',
  '0782',
  '0783',
  '0784',
  '0785',
  '0786',
  '0787',
  '0788',
  '0789',
  '0790',
  '0791',
  '0801',
  '0805',
  '0806',
  '0807',
  '0840',
  '0850',
  '0851',
  '0852',
  '0853',
  '0854',
  '0855',
  '0856',
  '0857',
  '0858',
  '0860',
  '0861',
  '0862',
  '0863',
  '0864',
  '0870',
  '0871',
  '0872',
  '0873',
  '0874',
  '0875',
  '0876',
  '0877',
  '0880',
  '0881',
  '0882',
  '0883',
  '0884',
  '0890',
  '0891',
  '0901',
  '0902',
  '0903',
  '0904',
  '0905',
  '0907',
  '0908',
  '0913',
  '0914',
  '0915',
  '0950',
  '0951',
  '0952',
  '0953',
  '0954',
  '0955',
  '0956',
  '0957',
  '0958',
  '0959',
  '0960',
  '0962',
  '0963',
  '0964',
  '0968',
  '0969',
  '0970',
  '0971',
  '0972',
  '0973',
  '0975',
  '0976',
  '0977',
  '0978',
  '0979',
  '0980',
  '0981',
  '0982',
  '0983',
  '0984',
  '0985',
  '0986',
  '0987',
  '0988',
]
