import React, { FC, useState } from 'react'
import styled from 'styled-components'
import shortid from 'shortid'
import { firebase, firestore } from '../../../../firebase'
import { SpinnerLoader } from '../SpinnerLoader'
import { useUpdateBooking } from '../../../../services/api/useProject'
import { useBookingStore } from '../../../../services/store/useBookingStore'
import { StyledInput } from '../../BookingWizard'
import * as Sentry from '@sentry/react'
import { SeverityLevel } from '@sentry/react'


type ProcessedFileType = {
  fullPath: string
  md5Hash?: string | null
  downloadUrl: string
}

const uploadToFirebase = async (authUserUid: string, firebaseProjectId: string, files?: FileList) => {
  if (!files) return
  const filesPath = `uploads/${authUserUid}/projects`
  const uuid = shortid.generate()
  const uniqueFilesPath = `${filesPath}/${uuid}`

  const processedFiles: ProcessedFileType[] = []

  for await (const file of files) {
    const test1 = await firebase.storage().ref(`${uniqueFilesPath}/${file.name}`).put(file)
    const { fullPath, md5Hash } = test1.metadata

    processedFiles.push({ fullPath, md5Hash, downloadUrl: await test1.ref.getDownloadURL() })
  }
  const attachments = processedFiles.map((file) => file.fullPath);
  await firestore.doc(`projects/${firebaseProjectId}`).update({
    attachments
  })

  return processedFiles as ProcessedFileType[]
}

const firebaseObject = (arr?: string[]) => {
  if (!arr) return {}
  const obj: Record<string, boolean> = arr.reduce((acc, item) => {
    acc[item as string] = true;
    return acc;
  }, {} as Record<string, boolean>)
  return obj;
}

export const Step10: FC = () => {
  const { updateForm, clearForm, form, setFormSubmitted, createdProjectId, createdFirebaseAuthUser, createdFirebaseProjectId } = useBookingStore()
  const updateProject = useUpdateBooking()
  const [formInput, setFormInput] = useState({
    description: form.description,
    uploads: undefined,
    budget: form.budget,
    address: form.address,
  })
  const [loading, setLoading] = useState(false);

  const updateFormInput = (key: 'description' | 'budget' | 'address' | 'uploads', value: any) => {
    setFormInput((cs) => ({ ...cs, [key]: value }))
    updateForm(key, value)
  }

  const completeSubmit = async (e: any) => {
    e.preventDefault();
    window.dataLayer.push({
      event: 'b_complete_additional_details'
    });
    setLoading(true)
    const files = await uploadToFirebase(createdFirebaseAuthUser!.uid!, createdFirebaseProjectId!,  formInput.uploads)



    try {
        await firestore.doc(`projects/${createdFirebaseProjectId}`).update({
          description: formInput.description || '',
          budget: formInput.budget || '',
          address: formInput.address || '',
          title: form.address || 'Nytt prosjekt',
          rooms: firebaseObject(form.rooms),
          style: firebaseObject(form.styles),
          id: createdFirebaseProjectId
        })

      await updateProject.mutateAsync({
        createdProjectId,
        description: formInput.description,
        budget: formInput.budget,
        address: formInput.address,
        uploads: files?.map((file) => file.downloadUrl)
      })
    } catch(e) {
       Sentry.captureException(e, {
        level: 'fatal',
        user: { email: form.email },
        extra: { firebaseProjectId: createdFirebaseProjectId, backendId: createdProjectId }
      });
    }

    setLoading(false)
    clearForm()
    setFormSubmitted(true)
  }

  return (
    <StyledStep9>
      <textarea
        name="description"
        placeholder="Beskrivelse av det du trenger hjelp med..."
        cols={30}
        rows={5}
        value={formInput.description}
        onChange={({ target }) => updateFormInput('description', target.value)}
      />
      <label htmlFor="uploads">
        Legg til bilder eller video. Tegn gjerne en enkel plantegning for hånd, med mål
      </label>
      <input
        type="file"
        onChange={(e) => updateFormInput('uploads', e.target.files)}
        className="custom-file-input"
        name="uploads"
        id="uploads"
        multiple
      />
      <div className="additional" style={{  height: '142px' }}>
        <label htmlFor="budget">Hvor mye har du tenkt til å bruke på oppgradering totalt?</label>
        <label htmlFor="address">Prosjekt addresse</label>
        <StyledInput
          onChange={({ target }) => updateFormInput('budget', target.value)}
          value={formInput.budget}
          placeholder="99 99 99"
          type="text"
        />
        <StyledInput
          onChange={({ target }) => updateFormInput('address', target.value)}
          value={formInput.address}
          placeholder="Legg inn addresse"
        />
      </div>
      <div className='form-action'>{!loading ? <button onClick={completeSubmit}>Fullfør og send inn</button> : <SpinnerLoader />}</div>
    </StyledStep9>
  )
}

const StyledStep9 = styled.section`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 0 auto;

  textarea {
    width: 100%;
    height: 100%;
    padding: 10px 10px;
    font-weight: 300;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
    background-color: #f8f9fb;
    border: 1px solid #dfe2e7;
    max-width: 100%;
  }
  label {
    margin: 32px 0 8px 0;
    font-weight: 600;
  }

  .additional {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
    column-gap: 10px;
  }
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: 'Legg ved filer og bilder';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid black;
    border-radius: 3px;
    padding: 8px 8px;
    outline: none;
    white-space: nowrap;
    user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    font-weight: 700;
  }
  .custom-file-input:hover::before {
    border-color: black;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
  .form-action {
    margin: 0 auto;
    margin-top: 20px;
    button {
      cursor: pointer;
      color: white;
      text-decoration: none;
      background-color: #0000f2;
      padding: 16px 40px;
      font-weight: 600;
      border-radius: 4px;
      display: inline-block;
      width: fit-content;
      white-space: nowrap;
      border: none;
      margin-top: 20px;
      :disabled {
        background-color: lightgray;
        cursor: not-allowed;
      }
    }
  }
  @media screen and (max-width: 800px) {
    width: 90%;
  }
`
