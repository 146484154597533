import { AxiosError } from 'axios'
import { useMutation, useQuery } from 'react-query'
import { DesignerType } from '../../views/booking/data/designers';
import { arkivetApi } from './axios'

type MeetingTypeAvailabilityType = 'digital' | 'physical'

type ImageType = {
  altText: string;
  image: string;
};
export type DesignerSanityType = {
  _id: string,
  name: string,
  pageUrl: string,
  firebaseDesignerId: string,
  priceLevel: string,
  experienceLevel: string,
  intro: string,
  helpTypeAvailability: string[],
  meetingTypeAvailability: MeetingTypeAvailabilityType[],
  availability: string[]
  backgroundImage: ImageType,
  profileImage: ImageType,
  isAvailable: boolean,
}

export const useGetDesigners = () => {
  return useQuery<DesignerSanityType[], AxiosError>('designers', async () => {
    const resp = await arkivetApi.get('/v1/sanity/designer')

    return resp.data
  })
}

export const useCreateDesigner = () => {
  return useMutation<DesignerType[], AxiosError, DesignerType[]>(
    async (designers: DesignerType[]) => {
      const resp = await arkivetApi.post('/v1/sanity/designer', {designers})
      return resp.data
    },
  )
}
