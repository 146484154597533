import React from 'react'
import { Box } from 'rebass'

const FormError = ({ errors }) => {
  if (errors.message) {
    return <Box id='error' p={2} my={2} bg='red' color='white'>{errors.message}</Box>
  }
  return null
}
export default FormError
