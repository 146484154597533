import React, { FC, useState } from 'react'

import Nordisk from '../../images/styles/Nordisk.jpg'
import Fargerikt from '../../images/styles/Fargerikt.jpg'
import Industrielt from '../../images/styles/Industrielt.jpg'
import Maskulint from '../../images/styles/Maskulint.jpg'
import Klassisk from '../../images/styles/Klassisk.jpg'
import Landlig from '../../images/styles/Landlig.jpg'
import Minimalistisk from '../../images/styles/Minimalistisk.jpg'
import Retro from '../../images/styles/Retro.jpg'
import Annet from '../../images/styles/Annet.jpg'
import styled from 'styled-components'
import { STYLES } from '../../../../constants/styles'
import { useUpdateBooking } from '../../../../services/api/useProject'
import { useBookingStore } from '../../../../services/store/useBookingStore'
import { StyledButton } from '../../BookingWizard'

const images = {
  Nordisk,
  Retro,
  Fargerikt,
  Maskulint,
  Industrielt,
  Klassisk,
  Landlig,
  Minimalistisk,
  Annet,
}

export const Step9: FC = () => {
  const updateProject = useUpdateBooking()
  const { incrementStep, updateForm, form, createdProjectId } = useBookingStore()
  const [activeStyles, setActiveStyles] = useState<string[]>(form.styles || []);


  const updateStyles = (style: string) => {
    let newStyles = activeStyles;
    if (activeStyles.includes(style)) {
      newStyles = activeStyles.filter((item) => item !== style);
    } else {
      newStyles.push(style);
    }
    setActiveStyles(newStyles);
    updateForm('styles', newStyles)
  }

  const nextStep = async () => {
    window.dataLayer.push({
      event: 'b_complete_selected_styles',
      selectedStyles: activeStyles
    });
    await updateProject.mutateAsync({
      createdProjectId,
      styles: activeStyles,
    })
    incrementStep()
  }

  return (
    <StyledStep8>
      <h3>Du kan velge flere stiler!</h3>
      <div>
        {STYLES.map((item) => (
          <Style active={activeStyles.includes(item.value)} onClick={(style) => updateStyles(style)} key={item.value} name={item.name} value={item.value} />
        ))}
      </div>
      <StyledButton className="step-button" onClick={nextStep}>
        Gå videre
      </StyledButton>
    </StyledStep8>
  )
}

const Style: FC<{ name: string; value: string; active: boolean; onClick: (value: string) => void }> = ({ name, value, active, onClick }) => {
  const [isActive, setIsActive] = useState<boolean>(active);
  return (
    <StyledStyle isActive={isActive} onClick={() => {
        onClick(value)
        setIsActive(!isActive)
      }}>
      <img src={images[name as keyof typeof images]} alt={name} />
      <p>{name}</p>
    </StyledStyle>
  )
}

const StyledStep8 = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #5a626a;
  }
  div {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    width: 80%;
    margin: 0 auto;
  }
  .step-button {
    width: 200px;
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
`

const StyledStyle = styled.button<{ isActive: boolean }>`
  outline:  ${({ isActive }) => `4px solid ${isActive ? '#2378e2' : 'transparent'}` };
  color:  white;
  border: none;
  border-radius: 6px;
  width: 100%;
  height: 160px;
  cursor: pointer;
  position: relative;
  padding: 0;
  img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
  p {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 20px;
    font-weight: 600;
    font-size: 1.2rem;
    z-index: 100;
    text-shadow: rgb(0 0 0) 0px 0px 10px;
  }
`
