import { Box } from 'rebass'
import styled from 'styled-components'

const Container = styled(Box)`
  max-width: ${props => (props.maxWidth ? props.maxWidth : '980px')};
`
Container.defaultProps = {
  mx: 'auto',
  px: [3, 4],
  py: [3, 4]
}

export default Container
