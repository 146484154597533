import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useBookingStore } from '../../../../services/store/useBookingStore'
import { HelpTypeType, HELP_TYPE } from '../../data/helpType'

export const Step1: FC = () => {
  useEffect(() => {
    window.dataLayer.push({ event: 'b_start_empty_booking' })
  }, [])

  return (
    <StyledStep1>
      {HELP_TYPE.map((item) => (
        <HelpType
          key={item.slug}
          slug={item.slug}
          image={item.image}
          title={item.title}
          description={item.description}
          priceRange={item.priceRange}
          readMore={item.readMore}
        />
      ))}
    </StyledStep1>
  )
}

const HelpType: FC<HelpTypeType> = ({ image, title, description, priceRange, readMore, slug }) => {
  const [expanded, setExpanded] = useState(false)
  const { incrementStep, updateForm, setStep, preSelectedDesigner } = useBookingStore()

  useEffect(() => {
    if(preSelectedDesigner) {
      setStep(2);
    }

   }, [preSelectedDesigner])


  return (
    <StyledHelpType>
      <img src={image} alt={title} />
      <div className="info">
        <h3>{title}</h3>
        <p>{description}</p>
        <button
          onClick={() => {
            setExpanded(!expanded)
            // window.dataLayer.push({ event: 'b_product_read_more', product: slug })
            window.dataLayer.push({ event: 'b_product_read_more' })
          }}
        >
          Les mer
        </button>
        {expanded && (
          <>
            <p>{readMore.description}</p>
            {readMore.url && <a href={readMore.url}>{readMore.urlTitle}</a>}
            {readMore.prices && (
              <>
                <p>Pris:</p>
                <ul>
                  {readMore.prices.map((price) => (
                    <li key={price}>{price}</li>
                  ))}
                  <li>Alle priser er inklusive mva.</li>
                </ul>
              </>
            )}
          </>
        )}
      </div>
      <div className="price">
        <p>Prisantydning</p>
        <p>{priceRange}</p>
        <button
          onClick={() => {
            updateForm('helpType', slug)
            // window.dataLayer.push({ event: 'b_product_chosen', product: slug })
            window.dataLayer.push({ event: 'b_product_chosen' })

            if (preSelectedDesigner) {
              setStep(6)
            } else incrementStep()
          }}
        >
          Velg
        </button>
      </div>
    </StyledHelpType>
  )
}

const StyledStep1 = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const StyledHelpType = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr 200px;
  border: 2px solid #dfe2e7;
  border-radius: 4px;
  padding: 18px;

  img {
    width: 128px;
    height: 128px;
    object-fit: cover;
  }
  button,
  a {
    color: #0000f2;
    cursor: pointer;
  }
  .info {
    p {
      white-space: pre-wrap;
      line-height: 28px;
      font-size: 16px;
    }
    border-right: 1px solid #dfe2e7;
    h3 {
      font-size: 1.5rem;
      font-weight: 600;
    }
    p {
      line-height: 28px;
    }

    button {
      margin-top: 8px;
      background-color: inherit;
      border: none;
      padding: 0;
      text-decoration: underline;
      font-size: 16px;
    }
    a {
      line-height: 40px;
      font-weight: 500;
    }
    ul {
      list-style: none;
      li {
        line-height: 40px;
      }
    }
  }
  .price {
    margin-left: 8px;
    p {
      font-size: 14px;
      line-height: 20px;
    }
    p:last-of-type {
      font-weight: 600;
      margin-bottom: 10px;
    }
    button  {
      background-color: inherit;
      border: 1px solid #0000f2;
      padding: 6px 32px;
      border-radius: 4px;
      text-decoration: none;
      color: #0000f2;
    }
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    img {
      width: 100%;
    }
    .price {
      margin-top: 10px;
      margin-left: 0;
    }
  }
`
