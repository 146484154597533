import React, { FC } from 'react'
import styled from 'styled-components'

export const SpinnerLoader: FC<{ center?: boolean }> = ({ center }) => {
  return <StyledSpinnerLoader center={center} />
}

const StyledSpinnerLoader = styled.div<{center?: boolean}>`
${({ center }) => center && `
  position: absolute;
  top: 40%;
  left: 40%;
  `};
  border: 10px solid #f3f3f3;
  border-top: 10px solid #0000f2;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
