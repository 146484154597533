export const ROOMS = [
  { name: 'Soverom', value: 'soverom', image: 'bedroom' },
  { name: 'Stue', value: 'stue', image: 'livingroom' },
  { name: 'Lekerom', value: 'lekerom', image: 'playroom' },
  { name: 'Kontor', value: 'kontor', image: 'office' },
  { name: 'Gjesterom', value: 'gjesterom', image: 'guestroom' },
  { name: 'Spisestue', value: 'spisestue', image: 'diningroom' },
  { name: 'Bad', value: 'bad', image: 'bathroom' },
  { name: 'Inngangsparti/gang', value: 'Inngangsparti', image: 'other' },
  { name: 'Annet rom', value: 'annet', image: 'other' }
]
