import React, { FC, FormEvent, useState } from 'react'
import { Link } from '@reach/router'
import styled from 'styled-components'
import { useCreateProjectFromBooking, useUpdateBooking } from '../../../../services/api/useProject'
import { useBookingStore } from '../../../../services/store/useBookingStore'
import { StyledInput } from '../../BookingWizard'
import { SpinnerLoader } from '../SpinnerLoader'

const validEmail = (email?: string): boolean => {
  if (!email) return false
  return !!email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
}

export const Step2: FC = () => {
  const {
    incrementStep,
    updateForm,
    form,
    createdProjectId,
    setCreatedProjectId,
    preSelectedDesigner,
  } = useBookingStore()
  const createProject = useCreateProjectFromBooking()
  const updateProject = useUpdateBooking()
  // const [privacyChecked, setPrivacyChecked] = useState(false)
  // const [conditionsChecked, setConditionsChecked] = useState(false)
  const [formInput, setFormInput] = useState({
    email: form.email,
    phone: form.phone,
    postalCode: form.postalCode,
  })

  const [loading, setLoading] = useState(false)

  const onSubmitForm = async (e: FormEvent<HTMLFormElement>) => {
    window.dataLayer.push({ event: 'b_complete_about_project' })
    setLoading(true);
    e.preventDefault()
    if (createdProjectId) {
      await updateProject.mutateAsync({
        createdProjectId,
        postalCode: form.postalCode,
        email: form.email,
        phone: form.phone,
      })
      incrementStep()
      return
    }

    const { id } = await createProject.mutateAsync({
      postalCode: form.postalCode,
      email: form.email,
      phone: form.phone,
      helpType: form.helpType,
    })

    setCreatedProjectId(id)
    setLoading(false);
    incrementStep()
  }

  const updateFormInput = (key: 'email' | 'phone' | 'postalCode', value: any) => {
    setFormInput((cs) => ({ ...cs, [key]: value }))
    updateForm(key, value)
  }

  if(loading) {
    return <SpinnerWrapper><SpinnerLoader /></SpinnerWrapper>
  }

  return (
    <StyledStep2>
      <form onSubmit={onSubmitForm}>
        <label htmlFor="email">Din e-post</label>
        <StyledInput
          value={formInput.email}
          onChange={(e) => updateFormInput('email', e.target.value)}
          name="email"
          placeholder="Kontakt e-post"
          type="email"
        />
        <label htmlFor="phone">Telefon</label>
        <StyledInput
          value={formInput.phone}
          onChange={(e) => updateFormInput('phone', e.target.value)}
          name="phone"
          placeholder="Kontakt telefon"
          type="tel"
        />
        <label htmlFor="postalCode">Postnummer</label>
        <StyledInput
          value={formInput.postalCode}
          onChange={(e) => updateFormInput('postalCode', e.target.value)}
          name="postalCode"
          placeholder="Postnummer"
          type="text"
        />
        <button
          disabled={
            loading ||
            !validEmail(formInput.email) ||
            !formInput.postalCode ||
            !formInput.phone ||
            formInput.postalCode.length !== 4
          }
          type="submit"
        >
          Gå videre
        </button>
      </form>
    </StyledStep2>
  )
}

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const StyledStep2 = styled.section`
  form {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 0 auto;
    margin-top: 60px;
    label {
      font-weight: 600;
      margin-bottom: 8px;
    }
    input {
      margin-bottom: 20px;
    }
    button {
      cursor: pointer;
      color: white;
      text-decoration: none;
      background-color: #0000f2;
      padding: 16px 40px;
      font-weight: 600;
      border-radius: 4px;
      display: inline-block;
      width: fit-content;
      white-space: nowrap;
      border: none;
      margin: 0 auto;
      margin-top: 20px;
      :disabled {
        background-color: lightgray;
        cursor: not-allowed;
      }
    }
    @media screen and (max-width: 800px) {
      width: 90%;
    }
  }
`
