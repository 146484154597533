import React, { FC, useState } from 'react'
import styled from 'styled-components'

import bedroom from '../../images/rooms/bedroom.svg'
import livingroom from '../../images/rooms/livingroom.svg'
import playroom from '../../images/rooms/playroom.svg'
import office from '../../images/rooms/office.svg'
import guestroom from '../../images/rooms/guestroom.svg'
import diningroom from '../../images/rooms/diningroom.svg'
import bathroom from '../../images/rooms/bathroom.svg'
import other from '../../images/rooms/other.svg'
import { ROOMS } from '../../../../constants/rooms'
import { useUpdateBooking } from '../../../../services/api/useProject'
import { useBookingStore } from '../../../../services/store/useBookingStore'
import { StyledButton } from '../../BookingWizard'

const images = {
  bedroom,
  livingroom,
  playroom,
  office,
  guestroom,
  diningroom,
  bathroom,
  other,
}

export const Step8: FC = () => {
  const updateProject = useUpdateBooking()
  const { updateForm, incrementStep, form, createdProjectId } = useBookingStore()
  const [activeRooms, setActiveRooms] = useState<string[]>(form.rooms || []);

  const updateRooms = (room: string) => {
    let newRooms = activeRooms;
    if (activeRooms.includes(room)) {
      newRooms = activeRooms.filter((item) => item !== room);
    } else {
      newRooms.push(room);
    }
    setActiveRooms(newRooms);
    updateForm('rooms', newRooms)
  }

  const nextStep = async () => {
    window.dataLayer.push({
      event: 'b_complete_selected_rooms',
      selectedRooms: activeRooms
    });
    await updateProject.mutateAsync({
      createdProjectId,
      rooms: activeRooms,
    })
    incrementStep()
  }

  return (
    <StyledStep7>
      <h3>Du kan velge flere rom!</h3>
      <div>
        {ROOMS.map((item) => (
          <Room active={activeRooms.includes(item.value)} onClick={(room) => updateRooms(room)} key={item.value} name={item.name} value={item.value} image={item.image} />
        ))}
      </div>
      <StyledButton className='step-button' onClick={nextStep}>Gå videre</StyledButton>
    </StyledStep7>
  )
}

const Room: FC<{ name: string; value: string; image: string, active: boolean; onClick: (value: string) => void }> = ({ name, value, active, image, onClick }) => {
  const [isActive, setIsActive] = useState<boolean>(active);
  return (
    <StyledRoom isActive={isActive} onClick={() => {
      onClick(value)
      setIsActive(!isActive)
      }}>
      <img src={images[image as keyof typeof images]} alt={name} />
      <p>{name}</p>
    </StyledRoom>
  )
}

const StyledRoom = styled.button<{isActive: boolean}>`
  border: none;
  background-color:  ${({ isActive }) => isActive ? '#2378e2' : '#e4f6fe'};
  color:  ${({ isActive }) => isActive ? 'white' : 'black'};
  border-radius: 4px;
  padding: 1rem;
  width: 100%;
  height: 200px;
  cursor: pointer;
  img {
    height: 50px;
  }
  p {
    margin-top: 20px;
    font-weight: 600;
    font-size: 1.1em;
  }
`

const StyledStep7 = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #5a626a;
  }
  div {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    width: 80%;
    margin: 0 auto;
  }
  .step-button {
    width: 200px;
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
`
