import React from 'react'
import styled from 'styled-components'
import logoBlack from '../../assets/images/logo-black.svg'

const LogoWrapper = styled.img`
  max-width: 100%;
  width: 25%;
  margin: 32px 0;
`
const LogoWrapperSmall = styled.img`
  max-width: 100%;
  width: 90px;
  float: left;
  margin-right: 15px;
`

export const LogoPrimary = () => <LogoWrapper src={logoBlack} alt='Arkivet logo' />

export const LogoSecondary = () => <LogoWrapperSmall src={logoBlack} alt='Arkivet logo' />
