import { Router, globalHistory } from '@reach/router'
import React, { Component, Fragment, lazy } from 'react'
import { Helmet } from 'react-helmet'
import { QueryClient, QueryClientProvider } from 'react-query'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { AuthProvider } from './AuthContext'
import Error from './components/Error'
import Footer from './components/Footer'
import * as routes from './constants/routes'
import { useBookingStore } from './services/store/useBookingStore'
import NotFound from './views/404'
import { BookingWizard } from './views/booking/BookingWizard'
import { HelpTypeComponent } from './views/booking/components/HelpTypeComponent'
import { HELP_TYPE } from './views/booking/data/helpType'

const MainMenu = lazy(() => import('./components/MainMenu'))
const Home = lazy(() => import('./views/home'))
const Designers = lazy(() => import('./views/designers'))
const Page = lazy(() => import('./views/pages'))
const Signup = lazy(() => import('./views/signup'))
const Login = lazy(() => import('./views/login'))
const Logout = lazy(() => import('./views/logout'))
const ForgotPassword = lazy(() => import('./views/forgotPassword'))
const User = lazy(() => import('./views/user'))
const Projects = lazy(() => import('./views/projects'))
const Project = lazy(() => import('./views/project'))
const ProjectNewApi = lazy(() => import('./views/project-new-api'))
const NewProject = lazy(() => import('./views/projects/new'))
const Settings = lazy(() => import('./views/settings'))
const Checkout = lazy(() => import('./views/checkout'))
const CheckoutSuccess = lazy(() => import('./views/checkout/success'))
const PaymentReceipt = lazy(() => import('./views/payment/receipt'))
const PaymentSalesReceipt = lazy(() => import('./views/payment/salesReceipt'))
const Admin = lazy(() => import('./views/admin'))

const queryClient = new QueryClient()

const functionWrapper = (BaseComponent) => (props) => {
  const store = useBookingStore()
  return <BaseComponent store={store} {...props} />
}

class App extends Component {
  state = { hasError: false }

  clearBooking = () => {
    const { store } = this.props
    if (
      window.location.pathname !== '/booking' &&
      localStorage.getItem('booking_store_step') !== null
    ) {
      store.clearForm()
    }
  }

  componentDidCatch(error, info) {
    console.log('componentDidCatch', { error }, { info })
    this.setState({ hasError: true, errors: error })
  }

  componentDidMount() {
    globalHistory.listen(() => {
      this.clearBooking()
    })
    this.clearBooking()
  }

  componentDidUpdate() {
    this.clearBooking()
  }

  render() {
    const { errors } = this.props

    return errors.allIds.length ? (
      <Error>
        {errors.allIds.map(
          (error) =>
            `En feil har oppstått: ${errors.byQuery[error].message} [${errors.byQuery[error].code}]`
        )}
      </Error>
    ) : this.state.hasError ? (
      <Error>
        En ukjent feil har oppstått. Forsøk å laste inn siden på nytt.{' '}
        {JSON.stringify(this.state.errors)}
      </Error>
    ) : (
      <Fragment>
        <Helmet
          defaultTitle="Markedsplassen for interiørdesign - Arkivet"
          titleTemplate="%s - Arkivet"
        >
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link
            href="https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap"
            rel="stylesheet"
          ></link>
        </Helmet>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Router>
              <MainMenu default>
                <NotFound default />
                <NotFound path="/404" />
                <Home path={routes.HOME} />
                <Designers path={routes.DESIGNERS} />
                <Page path={'/page/*'} />
                <Signup path={routes.SIGN_UP} />
                <Login path={routes.LOG_IN} />
                <Logout path={routes.LOG_OUT} />
                <ForgotPassword path={routes.FORGOT_PASSWORD} />
                <User path={`${routes.USERS}/:slug`} />
                <Projects path={routes.PROJECTS} />
                <ProjectNewApi path={`${routes.PROJECT_NEW_API}/:projectId`} />
                <Project path={`${routes.PROJECTS}/:id`} />
                <NewProject path={`${routes.PROJECTS}/new/:designerId/*`} />
                <Settings path={`${routes.SETTINGS}/*`} />
                <Checkout path={`${routes.CHECKOUT}/:paymentId`} />
                <CheckoutSuccess path={`${routes.CHECKOUT}/:paymentId/success/:projectId`} />
                <PaymentReceipt path={`${routes.PAYMENTS}/:paymentId/receipt`} />
                <PaymentSalesReceipt path={`${routes.PAYMENTS}/:paymentId/sales-receipt`} />
                <BookingWizard path={`${routes.BOOKING}`} />
                {HELP_TYPE.map(({ slug }) => (
                  <HelpTypeComponent key={slug} slug={slug} path={`${routes.BOOKING}/${slug}`} />
                ))}
              </MainMenu>
              <Admin path={`${routes.ADMIN}/*`} />
            </Router>
            <Footer />
          </AuthProvider>
        </QueryClientProvider>
      </Fragment>
    )
  }
}
export { App }

/**
 * firestoreConnect to redux store and firestore to reduce load times from firestore
 * If your using create-react-app, use firestoreConnect in App.js to listen to the collections you want synced with your redux store.
 */
export default compose(
  connect(({ firebase, firestore }) => ({
    auth: firebase.auth,
    errors: firestore.errors,
  })),
  firestoreConnect(() => ['users'])
)(functionWrapper(App))
