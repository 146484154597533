import React, { FC } from 'react'
import styled from 'styled-components'
import { useBookingStore } from '../../../../services/store/useBookingStore'

export const Step7: FC = () => {
  const { form, incrementStep } = useBookingStore()
  const week = form.selectedAvailability?.split('-')[0]

  return (
    <StyledStep6>
      <p>Vi glede oss til å møte deg.</p>
      <p>
        {`${form.designer?.name} vil snart ta kontakt for å planlegge oppstartsmøte i uke ${week}.`}
      </p>
      <p>
        Det kan hjelpe din designer med oppstart av prosjektet hvis du deler litt mer info i
        forkant.
      </p>
      <button
        onClick={() => {
          window.dataLayer.push({
            event: 'b_click_add_more_project_details',
          })

          incrementStep()
        }}
      >
        Del litt mer om ditt prosjekt
      </button>
    </StyledStep6>
  )
}

const StyledStep6 = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  text-align: center;
  img {
    width: 125px;
    height: 125px;
    margin-bottom: 24px;
  }
  p {
    margin: 20px 0;
  }
  button {
    margin-top: 20px;
    background-color: #0000f2;
    border: none;
    padding: 24px 44px;
    border-radius: 4px;
    text-decoration: none;
    color: white;
    font-weight: 600;
    cursor: pointer;
  }
`
