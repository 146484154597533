export const STYLES = [
  { name: 'Nordisk', value: 'nordisk', label: 'Nordisk' },
  { name: 'Retro', value: 'retro', label: 'Retro' },
  { name: 'Fargerikt', value: 'fargerikt', label: 'Fargerikt' },
  { name: 'Maskulint', value: 'maskulint', label: 'Maskulint' },
  { name: 'Industrielt', value: 'industrielt', label: 'Industrielt' },
  { name: 'Klassisk', value: 'klassisk', label: 'Klassisk' },
  { name: 'Landlig', value: 'landlig', label: 'Landlig' },
  { name: 'Minimalistisk', value: 'minimalistisk', label: 'Minimalistisk' },
  { name: 'Annet', value: 'annet', label: 'Annet' },
]
