import React, { FC, useEffect, useState } from 'react'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import styled from 'styled-components'
import { DesignerSanityType, useGetDesigners } from '../../../../services/api/useSanity'
import { useBookingStore } from '../../../../services/store/useBookingStore'
import { StyledButton } from '../../BookingWizard'
import { SpinnerLoader } from '../SpinnerLoader'

const Step5: FC<{ firestore?: any }> = ({ firestore }) => {
  const { data, isLoading } = useGetDesigners()

  const { form, setStep } =
  useBookingStore()

  console.log(form)

  let sorted = data
  const helpUserIdx = data?.findIndex((a) => {
    return a._id === '114b8c18-4094-4dff-9d7c-9d73e1e252e3'
  })
  if (helpUserIdx !== -1 && helpUserIdx) {
    sorted = data
      ?.slice(0, helpUserIdx)
      .concat(data?.slice(helpUserIdx + 1))
      .concat(data[helpUserIdx])
  }

  if(!form.selectedAvailability) {
    setStep(4)
  }

  return (
    <>
      {isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <SpinnerLoader />
        </div>
      )}
      <StyledStep5>
        {sorted?.map((designer) => (
          <DesignerCard key={designer._id} designer={designer} />
        ))}
      </StyledStep5>
    </>
  )
}

const DesignerCard: FC<{ designer: DesignerSanityType }> = ({ designer }) => {
  const { incrementStep, updateForm, form, preSelectedDesigner, setStep } = useBookingStore()
  // const updateProject = useUpdateBooking()
  // const sendBookingEmail = useSendBookingEmail()
  const [loading, setLoading] = useState(false)
  // const [error, setError] = useState(null)
  const {
    name,
    intro,
    priceLevel,
    experienceLevel,
    pageUrl,
    backgroundImage,
    profileImage,
    availability,
    helpTypeAvailability,
    firebaseDesignerId,
    isAvailable,
    meetingTypeAvailability,
  } = designer

  if (!isAvailable) {
    return null
  }

  if (!availability.some((item) => item === form.selectedAvailability)) {
    return null
  }
  if (!helpTypeAvailability.some((item) => item === form.helpType)) {
    return null
  }
  if (!meetingTypeAvailability.some((item) => item === form.meetingType)) {
    return null
  }

  if (preSelectedDesigner) {
    //Think this should be removed?
    // insertIntoFirebase({...form, selectedAvailability }, firestore, form.designer!.firebaseDesignerId)
    if (form.designer?.firebaseDesignerId === 'AOvb6yGI10ezhMbzLpNKMxLwdCW2') {
      setStep(6)
      return null
    }
  }

  const nextStep = async () => {
    setLoading(true)
    // If the form contains a designer, it means that the project has already been created in firebase
    if (!form.designer || form.designer.firebaseDesignerId !== firebaseDesignerId) {
      // window.dataLayer.push({ event: 'b_complete_selected_designer', selectedDesigner: form.designer?.firebaseDesignerId + ' - ' + form.designer?.name })
      window.dataLayer.push({ event: 'b_complete_selected_designer' })

      updateForm('designer', designer)
    }
    setLoading(false)
    incrementStep()
  }

  return (
    <>
      {
        <StyledDesignerCard>
          {!loading ? (
            <>
              <div className="image-container">
                <img src={backgroundImage.image} alt={backgroundImage.altText} />
                <img src={profileImage.image} alt={profileImage.altText} />
              </div>
              <h3>{name}</h3>
              <p className="intro">
                {intro === 'interiorArchitect' ? 'Interiørarkitekt' : 'Interiørdesigner'}
              </p>
              <div className="level-wrapper">
                <p>Prisnivå:</p>
                <div
                  style={{
                    backgroundColor: Number(priceLevel) >= 1 ? '#0000f2' : '#c8ccd2',
                  }}
                />
                <div
                  style={{
                    backgroundColor: Number(priceLevel) >= 2 ? '#0000f2' : '#c8ccd2',
                  }}
                />
                <div
                  style={{
                    backgroundColor: Number(priceLevel) >= 3 ? '#0000f2' : '#c8ccd2',
                  }}
                />
              </div>
              <div className="level-wrapper">
                <p>Kompetansetrinn:</p>
                <div
                  style={{
                    backgroundColor: Number(experienceLevel) >= 1 ? '#0000f2' : '#c8ccd2',
                  }}
                />
                <div
                  style={{
                    backgroundColor: Number(experienceLevel) >= 2 ? '#0000f2' : '#c8ccd2',
                  }}
                />
                <div
                  style={{
                    backgroundColor: Number(experienceLevel) >= 3 ? '#0000f2' : '#c8ccd2',
                  }}
                />
              </div>
              <div className="actions">
                <a
                  href={pageUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    // window.dataLayer.push({ event: 'b_designer_click_read_more', selectedDesigner: form.designer?.firebaseDesignerId + ' - ' + form.designer?.name })
                    window.dataLayer.push({ event: 'b_designer_click_read_more' })
                  }}
                >
                  Se profil
                </a>
                <StyledButton disabled={loading} onClick={nextStep}>Velg</StyledButton>
              </div>
            </>
          ) : (
            <SpinnerLoader center={true} />
          )}
        </StyledDesignerCard>
      }
    </>
  )
}

export default compose(firestoreConnect())(Step5)

const StyledDesignerCard = styled.div`
  position: relative;
  width: 100%;
  height: 480px;
  border: 2px solid rgb(243 244 246/1);
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  .image-container {
    position: relative;
    height: 320px;
    img:first-child {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    img:last-child {
      object-fit: contain;
      object-position: right bottom;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 40%;
      height: 40%;
    }
  }
  h3 {
    font-weight: 600;
    font-size: 1.2rem;
  }
  .intro,
  .level-wrapper,
  .experience-wrapper {
    color: rgb(107 114 128/1);
  }
  .intro {
    font-weight: 500;
    margin-bottom: 10px;
  }
  .level-wrapper {
    display: flex;
    align-items: center;
    div {
      margin-left: 5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: auto;
    button {
      cursor: pointer;
      background-color: inherit;
      border: 1px solid #0000f2;
      color: #0000f2;
      padding: 0.4rem 1.5rem;
      border-radius: 4px;
    }
    a {
      cursor: pointer;
      color: #0000f2;
    }
  }
`

const StyledStep5 = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
`
