import { css } from 'styled-components'

export const breakpoints = ['32em', '48em', '64em', '80em']

const sizes = {
  large: breakpoints[3],
  desktop: breakpoints[2],
  tablet: breakpoints[1],
  phone: breakpoints[0]
}

// Iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}) {
      ${css(...args)};
    }
  `
  return acc
}, {})
