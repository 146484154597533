import axios from 'axios'

const arkivetApiAddress = (() => {
  if (window.location.hostname === 'localhost') {
    return 'http://localhost:4000'
  }
  if (window.location.hostname.endsWith('.test.iterate.no')) {
    return 'https://backend.arkivet.test.iterate.no/'
  }
  return 'https://backend.arkivet.co'
})()

export const arkivetApi = axios.create({
  withCredentials: true,
  baseURL: arkivetApiAddress,
})

arkivetApi.interceptors.request.use((req) => {
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('token')

  if (token) {
    req.params = urlParams
  }
  return req
})
