import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'
import { isProduction, resolveEnv } from '../utils/url'

const config = {
  apiKey: isProduction() ? process.env.REACT_APP_API_KEY_PROD : process.env.REACT_APP_API_KEY_DEV,
  authDomain: isProduction()
    ? process.env.REACT_APP_AUTH_DOMAIN_PROD
    : process.env.REACT_APP_AUTH_DOMAIN_DEV,
  databaseURL: isProduction()
    ? process.env.REACT_APP_DATABASE_URL_PROD
    : process.env.REACT_APP_DATABASE_URL_DEV,
  projectId: isProduction()
    ? process.env.REACT_APP_PROJECT_ID_PROD
    : process.env.REACT_APP_PROJECT_ID_DEV,
  storageBucket: isProduction()
    ? process.env.REACT_APP_STORAGE_BUCKET_PROD
    : process.env.REACT_APP_STORAGE_BUCKET_DEV,
  messagingSenderId: isProduction()
    ? process.env.REACT_APP_MESSAGE_SENDER_ID_PROD
    : process.env.REACT_APP_MESSAGE_SENDER_ID_DEV,
}

// Sett
// REACT_APP_CURRENT_ENV='production'
// i .env for å overstyre
if (!firebase.apps.length) {
  firebase.initializeApp(config)
}

console.log('Firebase initialized with env ' + resolveEnv())

// rrf = react-redux-firebase
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true, // Store in Firestore instead of Real Time DB
  // useFirestoreForStorageMeta: true, // Store in Firestore instead of Real Time DB
  enableLogging: false,
  logErrors: true,
}

const firestore = firebase.firestore()
// const settings = { timestampsInSnapshots: true }
// firestore.settings(settings)
// firestore.enablePersistence({ experimentalTabSynchronization: true })

export { firebase, firestore, rrfConfig }
