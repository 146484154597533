import { User } from 'firebase';
import create from 'zustand';
import { firestore, firebase } from '../../firebase';
import { getLocalStorageItem, setLocalStorageItem } from '../../utils/localStorage';
import { DesignerSanityType } from '../api/useSanity';

export type FormType = {
  helpType?: string,
  email?: string,
  phone?: string,
  postalCode?: string,
  meetingType?: 'physical' | 'digital',
  selectedAvailability?: string;
  designer?: DesignerSanityType,
  rooms?: string[],
  styles?: string[],
  description?: string,
  uploads?: string[],
  budget?: string,
  address?: string,
}

type StateStoreType = {
  currentStep: number,
  form: FormType,
  formSubmitted: boolean;
  createdProjectId?: string;
  createdFirebaseAuthUser?: User;
  createdFirebaseAuthUserPassword?: string;
  createdFirebaseProjectId?: string;
  preSelectedDesigner: boolean;
  setFormSubmitted: (value: boolean) => void,
  incrementStep: () => void,
  decrementStep: () => void,
  setStep: (value: number) => void,
  setCreatedProjectId: (value: string) => void,
  updateForm: (key: keyof FormType, value: any) => void,
  setFirebaseAuthUser: (value: User) => void,
  setFirebaseAuthUserPassword: (value: string) => void,
  setFirebaseProjectId: (value: string) => void,
  setPreSelectedDesigner: (value: boolean) => void,
  insertIntoFirebase: (form: FormType, firestoreConnect: any, designerId: string) => Promise<any>,
  clearForm: () => void,
}

const BOOKING_STORE_STEP_STORAGE_KEY = 'booking_store_step'
const BOOKING_STORE_FORM_STORAGE_KEY = 'booking_store_form'
const BOOKING_STORE_CREATED_PROJECT_ID = 'booking_store_created_project_id'
const BOOKING_STORE_CREATED_FIREBASE_AUTH_USER = 'booking_store_created_firebase_auth_user'
const BOOKING_STORE_CREATED_FIREBASE_PROJECT_ID = 'booking_store_created_firebase_project_id'
const BOOKING_STORE_CREATED_FIREBASE_AUTH_USER_PASSWORD = 'booking_store_created_firebase_auth_user_password'
const BOOKING_STORE_PRE_SELECTED_DESIGNER = 'booking_store_pre_selected_designer'

export const useBookingStore = create<StateStoreType>((set, get) => ({
  currentStep: getLocalStorageItem(BOOKING_STORE_STEP_STORAGE_KEY) || 1,
  form: getLocalStorageItem(BOOKING_STORE_FORM_STORAGE_KEY) || {},
  formSubmitted: false,
  createdProjectId: getLocalStorageItem(BOOKING_STORE_CREATED_PROJECT_ID) || undefined,
  createdFirebaseAuthUser: getLocalStorageItem(BOOKING_STORE_CREATED_FIREBASE_AUTH_USER) || undefined,
  createdFirebaseProjectId: getLocalStorageItem(BOOKING_STORE_CREATED_FIREBASE_PROJECT_ID) || undefined,
  createdFirebaseAuthUserPassword: getLocalStorageItem(BOOKING_STORE_CREATED_FIREBASE_AUTH_USER_PASSWORD) || undefined,
  preSelectedDesigner: getLocalStorageItem(BOOKING_STORE_PRE_SELECTED_DESIGNER) || false,
  setFormSubmitted: (formSubmitted: boolean) => set(() => ({ formSubmitted })),
  incrementStep: () => set((state) => {
    const currentStep = state.currentStep + 1
    setLocalStorageItem(BOOKING_STORE_STEP_STORAGE_KEY, currentStep)

    return { currentStep }
  }),
  decrementStep: () => set((state) => {
    const currentStep = state.currentStep - 1
    setLocalStorageItem(BOOKING_STORE_STEP_STORAGE_KEY, currentStep)
    return { currentStep }
  }),
  setStep: (step: number) => set(() => {
    setLocalStorageItem(BOOKING_STORE_STEP_STORAGE_KEY, step)
    return { currentStep: step }
  }),
  updateForm: (key, value) => set((state) => {
    const form = { ...state.form, [key]: value }
    setLocalStorageItem(BOOKING_STORE_FORM_STORAGE_KEY, form)
    return {
      form
    }
  }),
  setCreatedProjectId: (createdProjectId: string) => set(() => {
    setLocalStorageItem(BOOKING_STORE_CREATED_PROJECT_ID, createdProjectId)
    return { createdProjectId }
  }),
  setFirebaseAuthUser: (createdFirebaseAuthUser: User) => set(() => {
    setLocalStorageItem(BOOKING_STORE_CREATED_FIREBASE_AUTH_USER, createdFirebaseAuthUser)
    return {
      createdFirebaseAuthUser
    }
  }),
  setFirebaseAuthUserPassword: (createdFirebaseAuthUserPassword: string) => set(() => {
    setLocalStorageItem(BOOKING_STORE_CREATED_FIREBASE_AUTH_USER_PASSWORD, createdFirebaseAuthUserPassword)
    return {
      createdFirebaseAuthUserPassword
    }
  }),
  setFirebaseProjectId: (createdFirebaseProjectId: string) => set(() => {
    setLocalStorageItem(BOOKING_STORE_CREATED_FIREBASE_PROJECT_ID, createdFirebaseProjectId)
    return {
      createdFirebaseProjectId
    }
  }),
  setPreSelectedDesigner: (preSelectedDesigner: boolean) => set(() => {
    setLocalStorageItem(BOOKING_STORE_PRE_SELECTED_DESIGNER, preSelectedDesigner)
    return { preSelectedDesigner }}),
  insertIntoFirebase: async (form, firestoreConnect, designerId) => {
    const response = await insertToFirebase(form, firestoreConnect, designerId)
    return response
  },
  clearForm: () => set(() => {
    localStorage.removeItem(BOOKING_STORE_STEP_STORAGE_KEY)
    localStorage.removeItem(BOOKING_STORE_FORM_STORAGE_KEY)
    localStorage.removeItem(BOOKING_STORE_CREATED_FIREBASE_AUTH_USER)
    localStorage.removeItem(BOOKING_STORE_CREATED_FIREBASE_PROJECT_ID)
    localStorage.removeItem(BOOKING_STORE_CREATED_FIREBASE_AUTH_USER_PASSWORD)
    localStorage.removeItem(BOOKING_STORE_PRE_SELECTED_DESIGNER)
    localStorage.removeItem(BOOKING_STORE_CREATED_PROJECT_ID)
    return {
      currentStep: 1,
      form: {},
      createdProjectId: undefined,
      createdFirebaseAuthUser: undefined,
      createdFirebaseProjectId: undefined,
      createdFirebaseAuthUserPassword: undefined,
      preSelectedDesigner: false,
    }
  })
}))

const randomPassword = () => Math.random().toString(36).slice(-8);

// Using firestoreConnect to trigger "onCreate" function for project and chat
async function insertToFirebase (value: FormType, firestoreConnect: any, designerId: string) {
  const { setFirebaseAuthUser, setFirebaseProjectId, setFirebaseAuthUserPassword } = useBookingStore.getState()
  if (!value.designer || !designerId) {
    return;
  }
  const serverTimestamp = firestoreConnect.FieldValue.serverTimestamp()
  let authUserPassword = randomPassword()
  let authUser: any = {};
  const existingUser = await firestore.collection('users').where('email', '==', value.email).get()
  const userAlreadyExists = existingUser.size !== 0;
  if (userAlreadyExists) {
    authUser = existingUser.docs[0].data()
    authUserPassword = '********'
  } else {
    const credentials = await firebase.auth().createUserWithEmailAndPassword(value.email!, authUserPassword)
    authUser = credentials.user;
  }
  if (!authUser) return
  if (!userAlreadyExists) {
    await firestore.collection('users').doc(authUser.uid).set({
      uid: authUser.uid,
      email: value.email,
      firstName: '',
      lastName: '',
      fullName: '',
      phoneNumber: value.phone,
      roles: {
        admin: false,
        designer: false,
        subscriber: true
      },
      designer: false,
      acceptsMarketing: false,
      createdAt: serverTimestamp,
      updatedAt: serverTimestamp,
    })

    await firestore.collection('public_users').doc(`public_${authUser.uid}`).set({
      uid: authUser.uid,
      firstName: '',
      lastName: '',
      fullName: '',
      createdAt: serverTimestamp,
      updatedAt: serverTimestamp,
    })
  }

  const { id } = await firestoreConnect.add('chats/', {
    members: {
      [authUser.uid]: true,
      [designerId]: true,
      '452vpqF8oftL09RTBVS0': true, // Arkivet Bot™
    },
    createdAt: serverTimestamp,
    updatedAt: serverTimestamp,
  })
  const project = await firestoreConnect.add('projects/', {
    title: 'Nytt prosjekt',
    designer: designerId,
    customer: authUser.uid,
    members: {
      [designerId]: true,
      [authUser.uid]: true,
    },
    chats: id,
    style: {},
    rooms: {},
    status: 'request',
    budget: '',
    description: '',
    attachments: [],
    meetingPreference: {},
    helpType: value.helpType ? value.helpType : '',
    meetingType: value.meetingType,
    initialMeetingRequest: value.selectedAvailability,
    postalCode: value.postalCode,
    createdAt: serverTimestamp,
    updatedAt: serverTimestamp,
  })
  setFirebaseAuthUser(authUser)
  setFirebaseProjectId(project.id)
  setFirebaseAuthUserPassword(authUserPassword)

  return { authUser, firebaseProjectId: project.id, authUserPassword };
}
