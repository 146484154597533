import React from 'react'
import styled from 'styled-components'
import { Field } from 'formik'

const arrow = encodeURI(
  `data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewbox='0 0 32 32' fill='currentcolor'> <path d='M0 6 L32 6 L16 28 z' /> </svg>`
)

const StyledSelect = styled(Field)`
  font-family: inherit;
  font-size: inherit;
  line-height: 1;
  appearance: none;
  border: 0;
  color: inherit;
  background-color: ${props => props.theme.colors['gray']};
  background-image: url("${arrow}");
  background-position: calc(100% - 8px) center;
  background-repeat: no-repeat;
  background-size: 8px 8px;
  font-weight: 500;
  padding-top: ${props => props.theme.space[2]}px;
  padding-bottom: ${props => props.theme.space[2]}px;
  padding-right: ${props => props.theme.space[4]}px;
  padding-left: ${props => props.theme.space[3]}px;
  border-radius: ${props => props.theme.radii[3]}px;
    &:disabled {
      opacity: .25;
    }
`

const Select = props => <StyledSelect component='select' {...props} />

export default Select
