import { createStore, compose } from 'redux'
import { reactReduxFirebase } from 'react-redux-firebase'
import { reduxFirestore } from 'redux-firestore'
import { firebase, rrfConfig } from '../firebase'
import rootReducer from '../reducers'

export default function configureStore (initialState, history) {
  // Add reactReduxFirebase enhancer when making store creator
  const createStoreWithMiddleware = compose(
    reactReduxFirebase(firebase, rrfConfig),
    reduxFirestore(firebase),
    typeof window === 'object' && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f
  )(createStore)

  // Create store with reducers
  const store = createStoreWithMiddleware(rootReducer)

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers')
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}
